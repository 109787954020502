var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.topleft",
          value: _vm.$t("Notifications"),
          expression: "$t('Notifications')",
          modifiers: { hover: true, topleft: true },
        },
      ],
      staticClass: "btn btn-link text-decoration-none pb-0",
      attrs: { type: "button", "data-cy": "notification-btn" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "label-pos",
          class: { "attention-marker-red": _vm.isAttentionNeeded },
        },
        [_c("i", { staticClass: "fd-alert text-dark" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }