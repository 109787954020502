var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "div",
        {
          staticClass: "body d-none d-lg-block d-none-for-print",
          class: { open: _vm.open },
        },
        [
          _c("span", { staticClass: "position-fixed" }, [
            _c(
              "a",
              { staticClass: "text-center", attrs: { href: "/" } },
              [
                _c("Logo", {
                  staticClass: "space-related-out-y",
                  attrs: { small: !_vm.open, "responsive-width": 28 },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "navbar-nav w-100", class: _vm.open && "open" },
              _vm._l(_vm.linksToShow, function (link, linkIndex) {
                return _c(
                  "RouterLink",
                  {
                    key: `sidenav_${linkIndex}`,
                    staticClass: "nav-item",
                    class: link.separator && "separator",
                    attrs: { tag: "li", to: link.to },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-flex nav-link text-white space-related-out-l",
                        class: !_vm.open && "closed",
                      },
                      [
                        _c("i", {
                          staticClass: "item-icon text-center",
                          class: `fd-${link.icon} ${_vm.open && "open"}`,
                        }),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "item-fade" } }, [
                          _vm.open
                            ? _c("span", { staticClass: "item-name" }, [
                                _vm._v(_vm._s(_vm.$t(link.name))),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.open && link.new
                          ? _c(
                              "span",
                              [_c("BadgeNew", { staticClass: "ml-2" })],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center btn btn-link toggle-button",
                on: {
                  click: function ($event) {
                    _vm.open = !_vm.open
                  },
                },
              },
              [
                _c("i", {
                  class: `fd-${_vm.open ? "chevron-left" : "chevron-right"}`,
                }),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }