import 'wicg-inert';
// Bootstrap
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';

import i18n from '@i18n';
import axios from 'axios';
import BootstrapVue from 'bootstrap-vue';
import { createPinia, PiniaVuePlugin, setMapStoreSuffix } from 'pinia';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import DatePicker from 'vue-bootstrap-datetimepicker';
import VueCookies from 'vue-cookies';
import VueGtm from 'vue-gtm';
import VueQuillEditor from 'vue-quill-editor';
import Rollbar from 'vue-rollbar';
import VueRouter from 'vue-router';
import Toastr from 'vue-toastr';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vuelidate from 'vuelidate';

import router from '@routes';

import authService from '@services/auth.service';

import store from '@store';
import { useModalStore } from '@store/pinia-utils/refresh';

import clickOutsideDirective from '@common/directives/click-outside.js';
import filters from '@common/filters';
import validators from '@common/validators.js';

import FdDatePicker from '@components/BaseDatePicker/fd-base-date-picker/fd-date-picker';
import BaseFilePreview from '@components/BaseFilePreview/BaseFilePreview';
import BaseSelect from '@components/BaseSelect';
import FdProgressBar from '@components/ProgressBar/fd-progressbar/fd-progress-bar';
import FdComponent from '@components/web-component-example/fd-component';

import App from '@/App.vue';
import FdTimelineChart from '@views/ActivityLog/Timeline/TimelineChart/fd-timeline-chart/fd-timeline-chart';
import FdOverviewRadialChart from '@views/Overview/components/fd-overview-radial-chart/fd-overview-radial-chart';

validators.init();

console.info(`%cApp version is: ${process.env.APP_VERSION}`, 'background: #222; color: #fff; padding: 10px;');

setMapStoreSuffix('Store');

const pinia = createPinia();

Vue.use(DatePicker);
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.use(PiniaVuePlugin);
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link', 'image', 'video'],
];

Vue.use(VueQuillEditor, {
    modules: {
        toolbar: toolbarOptions,
    },
});

let locale = Vue.prototype.$cookies.get('lang');
if (!locale) {
    locale = localStorage.getItem('lang') || 'en';
}

if (process.env.MIX_APP_ENV === 'production' || process.env.MIX_APP_ENV === 'staging') {
    Vue.use(Rollbar, {
        accessToken: process.env.MIX_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: true,
        source_map_enabled: true,
        environment: process.env.MIX_APP_ENV,
        payload: {
            client: {
                javascript: {
                    code_version: process.env.APP_VERSION,
                },
            },
        },
    });

    if (Vue.rollbar) {
        Vue.rollbar.configure({
            /**
             * An optional function that will be used to ignore uncaught exceptions based on its return value.
             * The function signature should be: function checkIgnore(isUncaught, args, payload) { ... } and should return
             * true if the error should be ignored.
             *
             * @param isUncaught Boolean - true if error caught in client, false if not
             * @param args Array - contains error message as array items
             * @param payload Object - The javascript object that is about to be sent to Rollbar. This will contain all
             * of the context and payload information for this notifier and error. This parameter is useful
             * for advanced ignore functionality
             *
             * @return Boolean - true if error should be ignored, otherwise false
             **/
            // eslint-disable-next-line no-unused-vars
            checkIgnore: function (isUncaught, args, payload) {
                const toIgnores = ['status code 401', 'status code 402', 'Network error'];
                return args && typeof args[0] === 'string' && toIgnores.some((toIgnore) => args[0].includes(toIgnore));
            },
        });
    }

    Vue.config.errorHandler = (err, vm, info) => {
        console.error(err, vm, info);
        Vue.rollbar.error(err);
    };
}

Vue.use(Toastr, {
    defaultTimeout: 10000,
    defaultPosition: 'toast-top-center',
    defaultCloseOnHover: false,
    defaultPreventDuplicates: true,
    defaultProgressBar: false,
});

Vue.use(VueRouter);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB1rmZvodn5toVSWM-AgUsUVcoL0A8bPCY',
        libraries: 'places', // This is required if you use the Autocomplete plugin
    },
});

Vue.use(VueGtm, {
    id: [process.env.GTM_ID],
    queryParams: (() => {
        return {
            production: {
                gtm_auth: process.env.GTM_PROD_AUTH,
                gtm_preview: process.env.GTM_PROD_PREVIEW,
                gtm_cookies_win: process.env.GTM_PROD_COOKIES_WIN,
            },
            development: {
                gtm_auth: process.env.GTM_DEV_AUTH,
                gtm_preview: process.env.GTM_DEV_PREVIEW,
                gtm_cookies_win: process.env.GTM_DEV_COOKIES_WIN,
            },
        }[process.env.NODE_ENV];
    })(),
    enabled: true,
    debug: process.env.NODE_ENV === 'development',
    loadScript: false,
    vueRouter: router,
    ignoredViews: [],
});

window.Popper = require('popper.js').default;
customElements.define('fd-overview-radial-chart', FdOverviewRadialChart);
customElements.define('fd-progress-bar', FdProgressBar);
customElements.define('fd-timeline-chart', FdTimelineChart);

// web components
customElements.define('fd-component', FdComponent);
customElements.define('fd-date-picker', FdDatePicker);

// vue components
import './global-components';
Vue.component('BaseFilePreview', BaseFilePreview);
Vue.component('BaseSelect', BaseSelect);

// Common directives
Vue.directive('click-outside', clickOutsideDirective);
Vue.directive('focus', {
    inserted: function (el, binding, vNode) {
        if (vNode && vNode.componentInstance && !vNode.componentInstance.val) {
            el.getElementsByTagName('input')[binding.value || 0].focus();
        }
    },
});

// TMP
// eslint-disable-next-line no-undef
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
    icons: {
        time: 'fd-clock',
        date: 'fd-calendar',
        up: 'fd-arrow-up',
        down: 'fd-arrow-down',
        previous: 'fd-chevron-left',
        next: 'fd-chevron-right',
        today: 'fd-calendar',
        clear: 'fd-trash',
        close: 'fd-close-circle',
    },
    format: 'DD.MM.YYYY HH:mm',
});

axios.defaults.baseURL = process.env.API_URL;
export let vm;

async function presetAppData() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('shared_access_token');

    if (token) {
        await store.dispatch('auth/setSharedToken', token);
    }

    if (authService.isAuthorized || authService.isSharedLink) {
        return await store.dispatch('initApp');
    }
}

// in Vue3, it will be:
// app.config.globalProperties.$filters = filters;
Vue.prototype.$filters = filters;
presetAppData().then(() => {
    vm = new Vue({
        el: '#app',

        i18n: i18n(),

        router,

        store,

        render: (h) => h(App),

        pinia,
    });
});

const errLogFn = console.error;
console.error = function (err, ...rest) {
    Vue?.rollbar?.error(err);
    errLogFn(err, ...rest);

    if (err?.name === 'ChunkLoadError' || err?.message?.includes('chunk')) {
        /**
         * Show new release message with refresh button
         * cause ChunkLoadError appears after a release
         * needs to be after app init for Pinia to exist
         */
        const modalStore = useModalStore();
        modalStore.show();
    }
};

if (window.Cypress) {
    // only available during E2E tests
    window.app = vm;
}

window.onerror = function (message, url, lineNo, columnNo, error) {
    if (typeof message === 'string' && message.includes('Uncaught SyntaxError: Unexpected token "<"')) {
        alert(vm.$t('New version has been released and application needs to be refreshed'));
        window.location.reload(true);
    } else {
        throw error;
    }
};
