var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row my-5 mx-auto" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-6 px-5 align-self-center" },
      [
        _c("h2", { staticClass: "mb-3" }, [_vm._t("title")], 2),
        _vm._v(" "),
        _vm._t("body-text", function () {
          return [
            _c("p", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t(_vm.bodyText)) +
                  "\n            "
              ),
            ]),
          ]
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [_vm._t("mainButton")], 2),
        _vm._v(" "),
        _c("div", [_vm._t("extraButton")], 2),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-none d-sm-none d-md-block col-6 align-self-center px-5",
      },
      [_c("img", { attrs: { src: _vm.pictureUrl, alt: _vm.pictureAlt } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }