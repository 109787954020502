var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Portal",
    { attrs: { order: _vm.order, to: "modal" } },
    [
      _vm.shown
        ? _c("BModal", {
            ref: `modal_${_vm.id}`,
            attrs: {
              id: _vm.id,
              visible: true,
              size: _vm.size,
              centered: _vm.centered,
              "modal-class": _vm.modalClass,
              "header-class": _vm.headerClass || "modalHeader",
              "footer-class": _vm.footerClass,
              "hide-header": _vm.hideHeader,
              "hide-footer": _vm.hideFooter,
              "ok-variant":
                _vm.okVariant ||
                (_vm.reverseButtonColors ? "outline-primary" : "primary"),
              "cancel-variant": _vm.reverseButtonColors
                ? "primary"
                : "outline-primary",
              "ok-title": _vm.okTitle || _vm.$t("continue"),
              "cancel-title": _vm.cancelTitle || _vm.$t("Cancel"),
              "ok-disabled": _vm.okDisabled,
              "no-close-on-backdrop": _vm.noClose,
            },
            on: {
              hidden: _vm.close,
              show: function ($event) {
                return _vm.$emit("show")
              },
              ok: function ($event) {
                return _vm.$emit("submit")
              },
              cancel: _vm.close,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "row w-100" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-10 col-md-6 my-auto order-md-0 text-break",
                          },
                          [
                            _vm.title
                              ? _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.title))]
                                )
                              : _vm._t("title"),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-2 col-md-1 my-auto text-right primary order-md-2",
                          },
                          [
                            _c(
                              "Action",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    value: _vm.$t("close"),
                                    expression: "$t('close')",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "cursor-pointer close-button",
                                attrs: { "aria-label": "Close", theme: "icon" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.close.apply(null, arguments)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fd-close icon-dark" })]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-md-5 my-auto text-right order-md-1",
                          },
                          [_vm._t("actions")],
                          2
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [_vm._t("default", null, { close: _vm.close })]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function () {
                    return [
                      _vm._t("modal-footer", null, {
                        cancel: _vm.close,
                        submit: () => _vm.$emit("submit"),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }