var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.layout === "horizontal"
    ? _c("div", { staticClass: "base-input" }, [
        _c("div", { staticClass: "form-group row justify-content-center" }, [
          _vm.title
            ? _c(
                "label",
                {
                  staticClass:
                    "col-md-2 col-form-label text-md-right align-self-md-center",
                  attrs: { id: _vm.id, for: _vm.id },
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "div",
              { staticClass: "text-right" },
              _vm._l(_vm.langs, function (item) {
                return _c(
                  "button",
                  {
                    key: item,
                    staticClass: "btn btn-sm text-uppercase",
                    class: { "btn-primary": item === _vm.lang },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setLang(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item) +
                        "\n                "
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data[_vm.lang],
                  expression: "data[lang]",
                },
              ],
              staticClass: "form-control",
              class: _vm.inputClass,
              attrs: { id: _vm.id, placeholder: _vm.placeholder },
              domProps: { value: _vm.data[_vm.lang] },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.data, _vm.lang, $event.target.value)
                  },
                  _vm.update,
                ],
              },
            }),
          ]),
        ]),
      ])
    : _c("div", [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("div", [
            _vm.title
              ? _c("label", { attrs: { for: _vm.id } }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _c("div", { staticClass: "form-group mb-0" }, [
                  _c("label", { attrs: { for: _vm.id } }, [_vm._t("label")], 2),
                ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group" },
            _vm._l(_vm.langs, function (item) {
              return _c(
                "button",
                {
                  key: item,
                  staticClass: "btn btn-sm text-uppercase",
                  class: { "btn-primary": item === _vm.lang },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.setLang(item)
                    },
                  },
                },
                [_vm._v("\n                " + _vm._s(item) + "\n            ")]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.data[_vm.lang],
              expression: "data[lang]",
            },
          ],
          staticClass: "form-control",
          class: _vm.inputClass,
          attrs: { id: _vm.id, placeholder: _vm.placeholder },
          domProps: { value: _vm.data[_vm.lang] },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.data, _vm.lang, $event.target.value)
              },
              _vm.update,
            ],
            blur: function ($event) {
              _vm.validation && _vm.validation.$touch()
            },
          },
        }),
        _vm._v(" "),
        _vm.validation && _vm.validation.$error
          ? _c(
              "span",
              { staticClass: "invalid-feedback" },
              [_vm._t("error", null, { validation: _vm.validation })],
              2
            )
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }