var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdownOnClickOutside,
          expression: "closeDropdownOnClickOutside",
        },
      ],
      on: {
        mouseleave: function ($event) {
          _vm.showDropdown = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "multiselect-wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.openDropdown.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "multiselect" }, [
            _c("div", { staticClass: "multiselect__custom-select" }, [
              _c("i", {
                class: `fd-chevron-${_vm.showDropdown ? "up" : "down"}`,
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.closeDropdown.apply(null, arguments)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "multiselect__tags" }, [
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDropdown,
                    expression: "showDropdown",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                ref: "searchBox",
                staticClass: "multiselect__input",
                attrs: {
                  autocomplete: "off",
                  placeholder: _vm.$t("Search"),
                  type: "text",
                },
                domProps: { value: _vm.search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.search = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              !_vm.showDropdown
                ? _c("span", { staticClass: "multiselect__single" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.selectedPlace.name) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showDropdown
        ? _c(
            "div",
            { staticClass: "dropdown" },
            [
              _vm._l(_vm.companies, function (company, index) {
                return _c(
                  "div",
                  {
                    key: company.id,
                    class: _vm.companies.length - 1 !== index && "mb-3",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "font-weight-bold company-name",
                        class: index === 0 && "first",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(company.name) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(company.places, function (place) {
                      return _c(
                        "div",
                        {
                          key: place.id,
                          staticClass: "place",
                          class:
                            _vm.selectedPlace.id === place.id && "selected",
                          on: {
                            click: function ($event) {
                              return _vm.choosePlace(company.id, place.id)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(place.name))])]
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              !_vm.companies.length
                ? _c("span", { staticClass: "ml-2" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("No such company was found")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }