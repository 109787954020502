var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-container" }, [
    _c(
      "div",
      { staticClass: "sort-filter-buttons-wrapper" },
      [
        _c("div", { staticClass: "sort-button" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-icon",
              on: {
                click: function ($event) {
                  return _vm.sortBy(_vm.internalSortKey)
                },
              },
            },
            [_c("i", { class: _vm.getSortIconClass(_vm.internalSortKey) })]
          ),
          _vm._v(" "),
          _vm.internalSortKeyLabel
            ? _c(
                "span",
                { staticClass: "badge-pill font-size-xxs bg-success-light" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.internalSortKeyLabel) +
                      "\n            "
                  ),
                ]
              )
            : _c(
                "span",
                {
                  staticClass: "badge-pill font-size-xxs bg-gray-200",
                  on: { click: _vm.toggleShowSortOptions },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("Sort by")) +
                      "\n            "
                  ),
                ]
              ),
        ]),
        _vm._v(" "),
        _vm._t("filter-slot"),
      ],
      2
    ),
    _vm._v(" "),
    _vm.showSortOptions
      ? _c(
          "div",
          { staticClass: "my-2 d-flex flex-wrap" },
          _vm._l(_vm.fields, function (field) {
            return _c(
              "span",
              {
                key: field.key,
                staticClass: "badge-pill font-size-xxs bg-gray-200 mb-1 mr-1",
                attrs: { id: field.key },
                on: {
                  click: function ($event) {
                    return _vm.sortBy(field.key)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t(field.label)) + "\n        "
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "table-wrapper" }, [
      _c(
        "table",
        {
          staticClass: "table layout-fixed",
          class: {
            "scrollable-table": _vm.scrollableTable,
            tableClass: _vm.tableClass,
          },
        },
        [
          _c("thead", [
            _c(
              "tr",
              { staticClass: "dark-border-bottom" },
              _vm._l(_vm.fields, function (field, index) {
                return _c(
                  "th",
                  {
                    key: field.key,
                    staticClass: "font-size-xxs",
                    class:
                      (index === _vm.fields.length - 1 && _vm.lastColSticky
                        ? "sticky-col "
                        : "") + field.thClass,
                    on: {
                      click: function ($event) {
                        return _vm.sortBy(field.key)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _vm._t(`head(${field.key})`, function () {
                          return [
                            _vm._v(
                              "\n                                " +
                                _vm._s(field.label) +
                                "\n                            "
                            ),
                          ]
                        }),
                        _vm._v(" "),
                        field.sortable
                          ? _c("span", { staticClass: "sort-icon" }, [
                              _c("i", {
                                class: _vm.getSortIconClass(field.key),
                              }),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.sortedAndPaginatedItems, function (item) {
                return _c(
                  "tr",
                  {
                    key: item.id,
                    class: _vm.rowClass(item),
                    attrs: { id: item.id },
                  },
                  _vm._l(_vm.fields, function (field, index) {
                    return _c(
                      "td",
                      {
                        key: field.key,
                        ref: _vm.setItemRef,
                        refInFor: true,
                        staticClass: "font-size-xs",
                        class:
                          (index === _vm.fields.length - 1 && _vm.lastColSticky
                            ? "sticky-col "
                            : "") + field.tdClass,
                        attrs: {
                          "data-field": field.key,
                          "data-item-id": item.id,
                          "data-label": field.label,
                        },
                        on: {
                          click: function ($event) {
                            !["actions", "files"].includes(field.key) &&
                              field.type !== "attach_file" &&
                              _vm.rowClicked(item)
                          },
                        },
                      },
                      [
                        _vm._t(
                          `cell(${field.key})`,
                          function () {
                            return [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.getRenderedText(item.id, field.key) ||
                                      item[field.key]
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          },
                          { item: item, value: item[field.key] }
                        ),
                      ],
                      2
                    )
                  }),
                  0
                )
              }),
              _vm._v(" "),
              _vm.items.length === 0
                ? [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-center font-size-xs",
                          attrs: { colspan: _vm.fields.length },
                        },
                        [
                          _vm._t("empty", function () {
                            return [
                              _vm._v(
                                _vm._s(_vm.$t("There are no records to show"))
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }