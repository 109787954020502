var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "grid-template-with-label": !_vm.ignoreFirst } },
    [
      !_vm.ignoreFirst
        ? _c(_vm.firstTag, { tag: "component" }, [_vm._t("first")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-row align-self-center" },
        [_vm._t("second")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }