var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.display
    ? _c(
        "aside",
        {
          staticClass:
            "alert alert-dismissible fade show info-box d-flex align-items-center space-related-in justify-content-between",
          class: {
            fill: _vm.boxType === "fill",
            outline: _vm.boxType !== "fill",
            className: _vm.className,
          },
          attrs: { "data-cy": "info-box" },
        },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("i", { staticClass: "fd-info info-box__icon" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "info-box__content" },
              [_vm._t("default")],
              2
            ),
          ]),
          _vm._v(" "),
          !_vm.noClose
            ? _c(
                "Action",
                {
                  staticClass: "close-button",
                  attrs: {
                    "data-cy": "close-btn",
                    "aria-label": "Close",
                    theme: "icon",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onClose.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "fd-close" })]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }