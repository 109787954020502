var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-control custom-radio",
      class: {
        "custom-control-inline": _vm.inline,
        "is-invalid": _vm.validation && _vm.validation.$error,
        "space-related-out-b": _vm.hasSpaceBetween,
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.valModel,
            expression: "valModel",
          },
        ],
        staticClass: "custom-control-input",
        class: { "is-invalid": _vm.invalid },
        attrs: {
          id: _vm.id,
          type: "radio",
          name: _vm.name,
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.val, checked: _vm._q(_vm.valModel, _vm.val) },
        on: {
          click: _vm.toggleSelect,
          change: function ($event) {
            _vm.valModel = _vm.val
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "custom-control-label", attrs: { for: _vm.id } },
        [_vm._v(_vm._s(_vm.$t(_vm.label)))]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }