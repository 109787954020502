/**
 * Endpoints and data types documentation in Jira:
 * https://fooddocs.atlassian.net/browse/FD-7367
 */

import { DateValue } from '@common/types';

import { allergensWithImages } from '@store/traceability/allergens';

import filters from '@common/filters';

function getFloat(number) {
    if (number && number.length) {
        const fixedNumber = typeof number === 'string' ? parseFloat(number) : number;
        const showDecimals = fixedNumber % 1 !== 0;
        return showDecimals ? filters.printNumTwoDeci(filters.twoDeci(number)) : number.split('.')[0];
    }
    return null;
}

// createdFromFhpId (food handling place) is for future reference and only used when creating the product
export class Product {
    id?: number;
    createdFromFhpId?: number;
    companyId?: number;
    name?: string;
    code?: string;
    ean?: string;
    isOutsourced?: boolean;
    isIngredient?: boolean;

    updatedAt?: Date;

    expirationTypeId?: number;
    expirationTimeUnitCount?: number;
    expirationTimeUnitId?: number;

    recipeTotalAmount?: number;
    recipeTotalAmountUnitId?: number;

    portionAmount?: number;
    portionAmountUnitId?: number;

    preparationInstructionsText?: string;
    ingredientsText?: string;
    generatedIngredientsText?: string;

    consumingGuideText?: string;
    storingConditionsText?: string;

    ingredients?: Ingredient[];

    allergens?: Allergen[];
    generatedAllergens?: Allergen[];

    fileList?: [];

    static fromJSON(json) {
        return {
            id: json.id,
            companyId: json.company_id,
            name: json.name,
            code: json.code,
            ean: json.ean,
            isOutsourced: json.is_outsourced,
            isIngredient: json.is_ingredient,

            updatedAt: new DateValue(json.updated_at).dateFormat,

            expirationTypeId: json.expiration_type_id,
            expirationTimeUnitCount: json.expiration_time_unit_count,
            expirationTimeUnitId: json.expiration_time_unit_id,

            recipeTotalAmount: getFloat(json.recipe_total_amount),
            recipeTotalAmountUnitId: json.recipe_total_amount_unit_id,

            portionAmount: getFloat(json.portion_amount),
            portionAmountUnitId: json.portion_amount_unit_id,

            preparationInstructionsText: json.preparation_instructions_text,

            ingredientsText: json.ingredients_text,
            generatedIngredientsText: json.generated_ingredients_text,

            consumingGuideText: json.consuming_guide_text,
            storingConditionsText: json.storing_conditions_text,

            ingredients: json.ingredients?.map(Ingredient.fromJSON),

            allergens: json.allergens?.map(Allergen.fromJSON),
            generatedAllergens: json.generated_allergens?.map(Allergen.fromJSON),

            fileList: json.file_list,
        };
    }

    static toJSON(product) {
        return {
            id: product.id,
            created_from_fhp_id: product.createdFromFhpId,
            company_id: product.companyId,
            name: product.name,
            code: product.code,
            ean: product.ean,
            is_outsourced: product.isOutsourced,
            is_ingredient: product.isIngredient || false,

            expiration_type_id: product.expirationTypeId,
            expiration_time_unit_count: product.expirationTimeUnitCount,
            expiration_time_unit_id: product.expirationTimeUnitId,

            recipe_total_amount: product.recipeTotalAmount,
            recipe_total_amount_unit_id: product.recipeTotalAmountUnitId,

            portion_amount: product.portionAmount,
            portion_amount_unit_id: product.portionAmountUnitId,

            preparation_instructions_text: product.preparationInstructionsText,

            ingredients_text: product.ingredientsText,

            consuming_guide_text: product.consumingGuideText,
            storing_conditions_text: product.storingConditionsText,

            ingredients: product.ingredients?.map(Ingredient.toJSON),
            allergens: product.allergens?.map(Allergen.toJSON),

            file_list: product.fileList,
        };
    }
}

export class Allergen {
    id?: number;
    name?: string;

    static fromJSON(json) {
        return {
            id: json.id,
            name: json.name,
        };
    }

    static fromJSONWithImage(json) {
        return allergensWithImages.find((allergen) => allergen.id === json.id);
    }

    static toJSON(allergen) {
        return {
            id: allergen.id,
            name: allergen.name,
        };
    }
}

export class Ingredient {
    id?: string;
    ingredientProductId?: number;
    ingredientProductName?: string;
    orderNumber?: number;
    amount?: string;
    amountUnitId?: number;

    static fromJSON(json) {
        return {
            id: json.id,
            ingredientProductId: json.ingredient_product_id,
            ingredientProductName: json.ingredient_product_name,
            orderNumber: json.order_number,
            amount: getFloat(json?.amount) || json?.amount,
            amountUnitId: json.amount_unit_id,
        };
    }

    static toJSON(ingredient) {
        const idObj = ingredient?.id ? { id: ingredient.id } : {};
        return {
            ...idObj,
            ingredient_product_id: ingredient?.ingredientProductId,
            ingredient_product_name: ingredient?.ingredientProductName,
            order_number: ingredient?.orderNumber,
            amount: ingredient?.amount?.toString(),
            amount_unit_id: ingredient?.amountUnitId,
        };
    }
}

export class CustomUnit {
    id?: string;
    name?: string;
    shortName?: string;
    baseUnitAmount?: string;
    baseUnitId?: number;
    usedInProducts?: boolean;

    static fromJSON(json) {
        return {
            id: json.id,
            name: json.name,
            shortName: json?.short_name,
            baseUnitAmount: getFloat(json.base_unit_amount),
            baseUnitId: json.base_unit_id,
            usedInProducts: json.used_in_products,
            custom: true,
        };
    }

    static defaultUnitFromJSON(json) {
        return {
            id: json.id,
            name: json.name,
            shortName: json?.short_name,
            baseUnitAmount: getFloat(json.measurement_system_base_unit_amt),
            baseUnitId: json.base_unit_id,
            usedInProducts: json.used_in_products,
            custom: false,
        };
    }

    static toJSON(customUnit) {
        return {
            id: customUnit.id,
            name: customUnit.name,
            short_name: customUnit.shortName,
            base_unit_amount: customUnit.baseUnitAmount,
            base_unit_id: customUnit.baseUnitId,
        };
    }
}

export class RecordIngredient extends Ingredient {
    expiryDate?: DateValue;
    batchNumber?: string;
    batchOrderNumber?: number;
    open?: boolean;

    static fromJSON(json, open = false) {
        return {
            ...super.fromJSON(json),
            expiryDate: json.expiry_date ? DateValue.fromRequestDate(json.expiry_date) : null,
            batchNumber: json?.batch_number || '',
            batchOrderNumber: json?.batch_order_number,
            open: open,
        };
    }

    static fromJSONWithBackupExpiryDate(json, backupExpiryDate) {
        return {
            ...this.fromJSON(json),
            expiryDate: json.expiry_date ? DateValue.fromRequestDate(json.expiry_date) : backupExpiryDate,
            open: true,
        };
    }

    static toJSON(ingredient) {
        return {
            ...super.toJSON(ingredient),
            expiry_date: ingredient.expiryDate?.isValid ? ingredient.expiryDate.requestDateFormat : null,
            batch_number: ingredient?.batchNumber,
            batch_order_number: ingredient?.batchOrderNumber,
        };
    }
}

export class TraceabilityStage {
    id?: number;
    placeId?: number;
    name?: string;
    color?: string;
    stageTypeId?: number;
    isPreparation?: boolean;

    static fromJSON(json) {
        return {
            id: json.id,
            placeId: json.fhp_id,
            name: json.name,
            color: json.color,
            stageTypeId: json.stage_type_id,
            isPreparation: json.is_preparation,
        };
    }
}

export class TraceabilityRecord {
    constructor() {}

    id?: number;
    productId?: number;
    productName?: string;
    createdBy?: number;
    amount?: number;
    amountUnitId?: number;
    ingredients?: RecordIngredient[];
    batchNumber?: string;
    expiryDate?: DateValue;
    comment?: string;
    traceabilityStageId?: number;
    traceabilityStage?: TraceabilityStage;
    hasIssues: boolean = false;

    public static fromJSON(json) {
        return {
            id: json?.id,
            productId: json?.product_id,
            productName: json?.product_name,
            createdBy: json?.created_by,
            createdAt: new DateValue(json?.created_at),
            amount: getFloat(json?.amount),
            amountUnitId: json?.amount_unit_id,
            ingredients: json.ingredients?.map((ingredient) => RecordIngredient.fromJSON(ingredient, false)),
            batchNumber: json?.batch_number || '',
            expiryDate: new DateValue(json?.expiry_date),
            comment: json?.comment,
            traceabilityStageId: json?.traceability_stage_id,
            hasIssues: json?.has_issues,

            rowOpen: false,
        };
    }

    static prefillFromJSON(json) {
        let expiryDate;
        if (json.expiry_date) {
            expiryDate = new DateValue(json?.expiry_date);
        } else {
            expiryDate = new DateValue();
            expiryDate.momentValue.add(1, 'days');
        }

        return {
            productId: json?.product_id,
            amount: getFloat(json?.amount),
            amountUnitId: json?.amount_unit_id,
            ingredients: json.ingredients?.map((ingredient) => RecordIngredient.fromJSON(ingredient)),
            batchNumber: json?.batch_number,
            expiryDate: expiryDate,
            isPortionUnitEnabled: json?.is_portion_unit_enabled,
            isRecipeTotalAmountDefined: json?.is_recipe_total_amount_defined,
        };
    }

    static toJSON(record) {
        return {
            id: record?.id,
            product_id: record?.productId,
            created_by: record?.createdBy,
            amount: record?.amount,
            amount_unit_id: record?.amountUnitId,
            ingredients: record?.ingredients?.map((ingredient) => RecordIngredient.toJSON(ingredient)),
            batch_number: record?.batchNumber,
            expiry_date: record?.expiryDate.requestDateTimeFormatNoSeconds,
            comment: record?.comment,
            traceability_stage_id: record?.traceabilityStage?.id,
        };
    }
}

export class SearchQuery {
    static toJSON(query) {
        const toDate = query?.recordsPeriod?.to
            ? query?.recordsPeriod?.to?.requestDateFormat
            : new DateValue().requestDateFormat;
        return {
            from_date: query?.recordsPeriod?.from?.requestDateFormat,
            to_date: toDate,
            expiry_from_date: query?.expiryPeriod?.from?.requestDateFormat,
            expiry_to_date: query?.expiryPeriod?.to?.requestDateFormat,
            product_name: query?.product?.name,
            product_batch_number: query?.product?.batch,
            ingredient_name: query?.ingredient?.name,
            ingredient_batch_number: query?.ingredient?.batch,
        };
    }
}
