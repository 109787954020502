import redirectToMonitoringWizardGuard from '@common/guards/redirectToMonitoringWizard.guard';

export default [
    {
        path: '',
        name: 'activity-log',
        component: () => import('./Index.vue'),
        redirect: { name: 'activity-log.timeline' },
        children: [
            {
                path: 'intro',
                name: 'activity-log.intro',
                redirect: { name: 'activity-log.intro.progress' },
                component: () => import('./MonitoringIntro/Index.vue'),
                children: [
                    {
                        path: 'progress',
                        name: 'activity-log.intro.progress',
                        component: () => import('./MonitoringIntro/pages/IntroProgress.vue'),
                    },
                    {
                        path: 'tasks',
                        name: 'activity-log.intro.tasks-simplified',
                        component: () => import('./MonitoringIntro/pages/TasksSimplified.vue'),
                    },
                    {
                        path: 'team',
                        name: 'activity-log.intro.team-simplified',
                        component: () => import('./MonitoringIntro/pages/TeamSimplified.vue'),
                    },
                    {
                        path: 'demo',
                        name: 'activity-log.intro.demo',
                        component: () => import('./MonitoringIntro/pages/BookDemoEmbed.vue'),
                    },
                ],
            },
            {
                path: 'app',
                name: 'activity-log.app',
                component: () => import('./MonitoringIntro/pages/DownloadApp.vue'),
            },
            {
                path: 'tasks',
                name: 'activity-log.tasks',
                component: () => import('@views/Tasks/Index.vue'),
                children: [
                    {
                        path: ':id',
                        name: 'activity-log.task',
                        component: () => import('@views/Tasks/panels/TaskPanel/index'),
                    },
                    {
                        path: 'new',
                        name: 'activity-log.task-new',
                        component: () => import('@views/Tasks/panels/TaskPanel/index'),
                    },
                ],
            },
            {
                path: 'tasks-mobile-setup',
                name: 'activity-log.tasks-mobile-setup',
                component: () => import('@views/Tasks/components/MobileSetup.vue'),
            },
            {
                path: 'equipment',
                name: 'activity-log.equipments',
                component: () => import('@views/Equipment/Index.vue'),
                children: [
                    {
                        path: ':id',
                        name: 'activity-log.equipment',
                        component: () => import('@views/Equipment/panels/EquipmentPanel.vue'),
                    },
                    {
                        path: 'new',
                        name: 'activity-log.equipment-new',
                        component: () => import('@views/Equipment/panels/EquipmentPanel.vue'),
                    },
                ],
            },
            {
                path: 'rooms',
                name: 'activity-log.rooms',
                component: () => import('@views/Rooms/Index.vue'),
                children: [
                    {
                        path: ':id',
                        name: 'activity-log.room',
                        component: () => import('@views/Rooms/panels/RoomPanel.vue'),
                    },
                    {
                        path: 'new',
                        name: 'activity-log.room-new',
                        component: () => import('@views/Rooms/panels/RoomPanel.vue'),
                    },
                ],
            },
            {
                path: 'pause-monitoring',
                name: 'activity-log.pause-monitoring',
                component: () => import('@views/PauseMonitoring/Index.vue'),
            },
            {
                path: 'timeline',
                beforeEnter: redirectToMonitoringWizardGuard,
                component: () => import('./Timeline/Timeline.vue'),
                children: [
                    {
                        path: '',
                        name: 'activity-log.timeline',
                        component: () => import('./Timeline/TimelineTable.vue'),
                    },
                    {
                        path: 'panel',
                        name: 'activity-log.timeline.entry-panel',
                        component: () => import('./components/TaskEntryPanel/index'),
                        children: [
                            {
                                path: 'checklist',
                                name: 'activity-log.timeline.entry-panel.checklist',
                                component: () => import('./components/TaskEntryPanel/ChecklistPanel/PanelChecklist'),
                            },
                            {
                                path: 'tasks',
                                name: 'activity-log.timeline.entry-panel.form',
                                component: () => import('./components/TaskEntryPanel/FormPanel/PanelForm'),
                            },
                            {
                                path: 'tickets',
                                name: 'activity-log.timeline.entry-panel.tickets',
                                component: () => import('./components/TaskEntryPanel/TicketPanel/index'),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'entries',
                component: () => import('./Entries.vue'),
                children: [
                    {
                        path: '',
                        name: 'activity-log.entries',
                        component: () => import('./GroupsList.vue'),
                    },
                    {
                        path: ':taskId/dates',
                        name: 'activity-log.entries.dates',
                        component: () => import('./GroupView.vue'),
                    },
                    {
                        path: ':taskId/units',
                        name: 'activity-log.entries.units',
                        component: () => import('./EntriesUnits.vue'),
                    },
                    {
                        path: ':taskId',
                        name: 'activity-log.entries.task',
                        component: () => import('./GroupView.vue'),
                        children: [
                            {
                                path: 'panel',
                                name: 'activity-log.entries.entry-panel',
                                component: () => import('./components/TaskEntryPanel/index'),
                                children: [
                                    {
                                        path: 'checklist',
                                        name: 'activity-log.entries.entry-panel.checklist',
                                        component: () =>
                                            import('./components/TaskEntryPanel/ChecklistPanel/PanelChecklist'),
                                    },
                                    {
                                        path: 'tasks',
                                        name: 'activity-log.entries.entry-panel.form',
                                        component: () => import('./components/TaskEntryPanel/FormPanel/PanelForm'),
                                    },
                                    {
                                        path: 'tickets',
                                        name: 'activity-log.entries.entry-panel.tickets',
                                        component: () => import('./components/TaskEntryPanel/TicketPanel/index'),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ':taskId/:unitId',
                        name: 'activity-log.entries.unit.task',
                        component: () => import('./GroupView.vue'),
                    },
                    {
                        path: ':taskId/versions',
                        name: 'activity-log.entries.versions',
                        component: () => import('./VersionList.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: 'verification',
        component: () => import('./ActivityLogVerification.vue'),
        children: [
            {
                path: '',
                name: 'activity-log.verification.list',
                component: () => import('./TaskVerificationList.vue'),
            },
            {
                path: ':taskId/entries',
                name: 'activity-log.verification.entries',
                component: () => import('./TaskVerificationView.vue'),
            },
            {
                path: ':taskId/versions',
                name: 'activity-log.verification.versions',
                component: () => import('./TaskVerificationVersions.vue'),
            },
        ],
    },
];
