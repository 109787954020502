var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      size: "md",
      "modal-class": "baseModal",
      "footer-class": "modalFooter",
      "hide-header": "",
      centered: "",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _c("img", {
                staticClass: "modal-image",
                attrs: { src: "/assets/img/illu_celebrate.svg", alt: "" },
              }),
              _vm._v(" "),
              _vm._t("default"),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c("span", [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-center d-flex align-items-center justify-content-center",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-primary mx-1",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("Cancel")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mx-1",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("submit")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("continue")) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-center mt-5" }, [
                  _vm._v(
                    "\n                " + _vm._s(_vm.$t("Not sure yet")) + " "
                  ),
                  _c("a", { attrs: { href: _vm.mailto } }, [
                    _vm._v(_vm._s(_vm.$t("take-contact-with-fooddocs"))),
                  ]),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }