var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.topleft",
          value: _vm.$t("Need help? Go to Knowledge Base"),
          expression: "$t('Need help? Go to Knowledge Base')",
          modifiers: { hover: true, topleft: true },
        },
      ],
      staticClass:
        "btn btn-link text-decoration-none d-flex align-items-center mx-1",
      attrs: {
        type: "button",
        "data-cy": "notification-btn",
        onclick: "window.open('https://www.fooddocs.com/knowledge', '_blank')",
      },
    },
    [
      _c("i", { staticClass: "fd-question font-size-lg mr-2" }),
      _vm._v(" "),
      _c("span", { staticClass: "font-size-xs" }, [
        _vm._v(_vm._s(_vm.$t("help"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }