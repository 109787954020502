var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center flex-wrap" },
    [
      _vm._l(_vm.displayedItems, function (badge) {
        return _c(
          "span",
          {
            key: badge.id,
            staticClass: "d-flex badge badge-pill badge-secondary m-1",
            class: `${_vm.disabled && "disabled"} ${
              _vm.clickable && "cursor-pointer"
            }`,
            attrs: { "data-cy": "role-value" },
            on: {
              click: function ($event) {
                return _vm.$emit("click", badge)
              },
            },
          },
          [
            !_vm.hideNoId || badge.id
              ? [
                  _c("div", { staticClass: "text my-auto" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.badgeText(badge)) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.disabled
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              value: _vm.$t("delete"),
                              expression: "$t('delete')",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "btn d-none-for-print",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openConfirmDelete(badge)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fd-close" })]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm.showCountBadge
        ? _c(
            "span",
            {
              staticClass: "d-flex badge badge-pill badge-secondary my-0 py-1",
            },
            [
              _vm._v(
                "\n        +" +
                  _vm._s(_vm.items.length - _vm.numberOfElements) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("action"),
      _vm._v(" "),
      _c("BaseDelete", {
        ref: "delete-modal",
        attrs: { "modal-only": "" },
        on: { delete: _vm.deleteItem },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }