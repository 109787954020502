<template>
    <div
        v-if="isEditable"
        ref="element"
        class="base-select multiselect-wrapper form-group mb-0"
        :class="{
            'border-0': borderless,
            'd-none-for-print': !val,
        }"
    >
        <TwoRow
            :ignore-first="!isLabelSlot"
            first-tag="label"
            class="h-100"
        >
            <template #first>
                <slot name="label"></slot>
            </template>
            <template #second>
                <ValidationProvider
                    v-slot="v"
                    :name="name"
                    :rules="rules"
                    mode="eager"
                    slim
                >
                    <!-- :block-keys has to be there since otherwise $isDisabled is throwing errors.
                    https://github.com/shentao/vue-multiselect/issues/937 for more info-->
                    <Multiselect
                        v-model="val"
                        :placeholder="placeholder || (searchable ? $t('Search') : $t('choose'))"
                        :options="options"
                        :allow-empty="!notEmpty"
                        :show-labels="false"
                        :label="label"
                        :max="max"
                        :custom-label="customLabel"
                        :track-by="trackBy"
                        :searchable="isSearchable"
                        :internal-search="internalSearch"
                        :multiple="multiple"
                        :taggable="taggable"
                        :disabled="disabled"
                        tag-position="bottom"
                        :tag-placeholder="`${$t('add')} +`"
                        :class="[
                            selectClass,
                            { 'is-invalid': (validation && validation.$error) || v.errors.length },
                            { 'no-dropdown': hideDropdown },
                        ]"
                        :block-keys="['Delete']"
                        :open-direction="isMobile ? 'bottom' : null"
                        :group-values="groupValues"
                        :group-label="groupLabel"
                        :aria-invalid="v.ariaInput['area-invalid']"
                        :aria-required="v.ariaInput['aria-required']"
                        @select="onSelect"
                        @remove="onRemove"
                        @search-change="onChange"
                        @open="onFocus"
                        @close="onBlur"
                        @tag="onTag"
                    >
                        <template #noResult>
                            <slot name="noResult"><span v-html="$t(notFoundMessage)"></span></slot>
                        </template>
                        <template #noOptions>
                            <span>{{ $t('Type to search') }}</span>
                        </template>
                        <!-- Custom singleLabel and option template for COUNTRY_CODE start -->
                        <template
                            v-if="optionType === 'country_code'"
                            #singleLabel="countryCodeProps"
                        >
                            <div class="d-flex flex-row">
                                <template v-if="countryCodeProps.option.phone_code">
                                    <img
                                        v-if="getImgName(countryCodeProps)"
                                        class="max-w-25px mr-2"
                                        :src="`https://translations.fooddocs.ee/app/flags/${getImgName(
                                            countryCodeProps
                                        )}.svg`"
                                        :alt="countryCodeProps.option.name"
                                    />
                                    <div class="d-inline-block">+{{ countryCodeProps.option.phone_code }}</div>
                                </template>
                                <template v-else>{{ countryCodeProps.option.$groupLabel }}</template>
                            </div>
                        </template>
                        <!-- Custom singleLabel and option template for COUNTRY_CODE end -->
                        <!-- Custom singleLabel and option template for INGREDIENT start -->
                        <template
                            v-if="optionType === 'ingredient'"
                            #singleLabel="ingredientProps"
                        >
                            <span :class="getIngredientClass(ingredientProps.option)">{{
                                getLabel(ingredientProps.option)
                            }}</span>
                        </template>
                        <template
                            v-if="optionType === 'ingredient'"
                            #option="ingredientOptionProps"
                        >
                            <span :class="getIngredientClass(ingredientOptionProps.option)">{{
                                getLabel(ingredientOptionProps.option)
                            }}</span>
                        </template>
                        <!-- Custom singleLabel and option template for INGREDIENT end -->
                        <!-- Custom singleLabel and option template for PAGINATOR start -->
                        <template
                            v-if="optionType === 'paginator'"
                            #singleLabel="paginatorProps"
                        >
                            {{ paginatorProps.option }} {{ $t('Per page').toLowerCase() }}
                        </template>
                        <template
                            v-if="optionType === 'paginator'"
                            #option="paginatorOptionProps"
                        >
                            {{ paginatorOptionProps.option }} {{ $t('Per page').toLowerCase() }}
                        </template>
                        <!-- Custom singleLabel and option template for PAGINATOR end -->
                        <template #caret>
                            <div class="multiselect__custom-select d-none-for-print">
                                <i
                                    v-if="!hideDropdown"
                                    class="fd-chevron-down"
                                    :class="arrowClass"
                                />
                            </div>
                        </template>
                        <template #afterList>
                            <div class="w-100 d-flex">
                                <slot name="afterList"></slot>
                            </div>
                        </template>
                    </Multiselect>

                    <span
                        v-if="isErrorSlot && validation && validation.$error"
                        class="invalid-feedback d-block"
                    >
                        <slot
                            name="error"
                            :validation="validation"
                        />
                    </span>
                    <span
                        v-if="v.errors.length"
                        class="invalid-feedback d-block"
                        data-cy="error-message"
                    >
                        {{ v.errors[0] }}
                    </span>
                </ValidationProvider>
            </template>
        </TwoRow>
    </div>
    <span v-else>
        <span v-if="customToString">{{ customToString }}</span>
        <span v-else-if="multiple">
            <span
                v-for="(v, i) in value"
                :key="i"
            >
                {{ v.text }}{{ i !== value.length - 1 ? ', ' : '' }}
            </span>
        </span>
        <span v-else>
            {{ stringify.text || stringify || '-' }}
        </span>
    </span>
</template>

<script>
import { v1 as uuidv1 } from 'uuid';
import Multiselect from 'vue-multiselect';

import { getPageScrollTop, isMobile } from '@services/helper.service';

import TwoRow from '@components/TwoRow';

export default {
    name: 'BaseSelect',

    components: {
        Multiselect,
        TwoRow,
    },

    props: {
        value: {
            type: [Object, String, Number, Array],
            default: '',
        },
        max: {
            type: Number,
            nullable: true,
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        trackBy: {
            type: String,
            nullable: true,
            default: null,
        },
        valueAs: {
            type: String,
            nullable: true,
            default: null,
        },
        validation: {
            type: Object,
            nullable: true,
            default: null,
        },
        customLabel: {
            type: Function,
            nullable: true,
            default: undefined,
        },
        selectClass: {
            type: [Object, String],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        customToString: {
            type: String,
            default: '',
        },
        arrowClass: {
            type: String,
            default: '',
        },
        groupValues: {
            type: String,
            default: '',
        },
        groupLabel: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        rules: {
            type: String,
            default: '',
        },
        optionType: {
            type: String,
            default: '',
        },
        notEmpty: {
            type: Boolean,
            default: false,
        },
        searchable: Boolean,
        multiple: Boolean,
        taggable: Boolean,
        hideDropdown: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },
        internalSearch: {
            type: Boolean,
            default: true,
        },
        disableAutoFocus: Boolean,
        borderless: Boolean,
        isEditable: {
            type: Boolean,
            default: true,
        },
        notFoundMessage: {
            type: String,
            default: 'Not found',
        },
    },

    emits: ['search-change', 'select', 'tag', 'remove', 'input'],

    data() {
        return {
            scrollTopLast: 0,
        };
    },

    computed: {
        stringify() {
            // Translation can't be inside render, because we can't add translation inside array iteration
            const translate = (val) => this.$t(val?.title || val?.name || val?.label || val || '-');
            if (Array.isArray(this.value)) {
                return this.value.map((val) => translate(val)).join(', ');
            } else {
                return translate(this.value);
            }
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        val: {
            get() {
                if (this.valueAs && this.options?.length) {
                    if (this.multiple) {
                        return this.value?.map((val) => this.options.find((i) => i[this.valueAs] === val));
                    }

                    return this.options.find((i) => i?.[this.valueAs] === this.value);
                }

                return this.value;
            },
            set(value) {
                if (this.valueAs) {
                    if (this.multiple) {
                        this.$emit(
                            'input',
                            value.map((i) => i[this.valueAs])
                        );
                        return;
                    }

                    this.$emit('input', value?.[this.valueAs] || value);
                    return;
                }

                this.$emit('input', value);
            },
        },
        isErrorSlot() {
            return !!(this.$scopedSlots.error || this.$slots.error);
        },
        isMobile() {
            return isMobile();
        },
        isSearchable() {
            return this.searchable; // && !this.isMobile
        },
    },

    methods: {
        onSelect(value) {
            if (this.validation) {
                this.validation.$touch();
            }

            this.$emit('select', value);
        },
        onChange(value) {
            this.$emit('search-change', value);
        },
        onTag(value) {
            this.$emit('tag', {
                id: uuidv1(),
                value,
            });
        },
        onRemove(value) {
            this.$emit('remove', value);
        },
        onFocus() {
            if (!this.disableAutoFocus && this.isMobile && this.searchable) {
                this.scrollTopLast = getPageScrollTop();
                const timeout = 500;
                setTimeout(() => {
                    const rect = this.$refs.element.getBoundingClientRect();
                    const padding = 50;

                    window.scrollTo(0, rect.top - padding);
                }, timeout);
            }
        },
        onBlur() {
            if (!this.disableAutoFocus && this.isMobile && this.searchable) {
                window.scrollTo(0, this.scrollTopLast);
            }
        },
        getImgName(props) {
            if (props && props.option && props.option.code) {
                return props.option.code.toLowerCase();
            }
            return null;
        },
        getIngredientClass(ingredient) {
            if (ingredient.custom_ingredient_id) {
                return 'font-weight-bold';
            } else if (ingredient.product) {
                return 'text-primary';
            }
            return null;
        },
        getLabel(ingredient) {
            if (ingredient.custom_ingredient_id || ingredient.product) {
                return this.customLabel(ingredient);
            }

            return '';
        },
    },
};
</script>

<style lang="scss">
.base-select {
    font-size: 0.875rem !important;

    label {
        font-weight: normal;
    }

    .multiselect__single,
    .multiselect__input {
        background-color: transparent !important;

        &:hover {
            background-color: transparent !important;
        }
    }

    * .multiselect__content {
        padding: 0.3rem !important;
    }

    .multiselect__tags {
        border-width: 1px;
        transition: border 0.3s ease-in-out;

        &:not(.is-invalid) {
            border: 1px solid transparent !important;
        }

        &:hover {
            border-color: $gray-400 !important;
        }
    }

    * input,
    * .multiselect__tags,
    * textarea {
        font-size: 0.875rem !important;
        color: black !important;
        background-color: $gray-200;
        border-color: transparent;
        border-width: 1px !important;

        &:hover {
            border-color: $gray-400 !important;

            & ~ .input-group-append div {
                background-color: white !important;
                border-color: black !important;
            }
        }

        &::placeholder {
            color: $gray-500;
        }
    }

    * .multiselect__option--highlight {
        background-color: transparent !important;
        border: 1px solid black;
        border-radius: 6px;
    }

    * .multiselect__option--selected {
        font-weight: normal !important;
        background-color: $gray-200 !important;
        border-radius: 6px !important;
    }
}

.multiselect-wrapper {
    @media print {
        .multiselect__tag-icon {
            display: none;
        }
        .multiselect__tag {
            color: black;
        }
    }

    &.border-0 {
        .multiselect__tags {
            border: 0 !important;
        }
    }

    &.w-auto {
        .multiselect__content-wrapper {
            width: auto;
            min-width: 100%;
            max-width: 80vw;
        }
    }

    .multiselect {
        cursor: pointer;

        &.dropdown-w-auto {
            .multiselect__content-wrapper {
                width: auto;
                min-width: 100%;
            }
        }

        &.is-invalid {
            .multiselect__tags {
                border-color: var(--red) !important;
            }
        }

        .multiselect__custom-select {
            height: 100%;
            cursor: pointer;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .multiselect__tags {
            display: flex;
            align-items: center;
            padding: 0.25rem 2.5rem 0.25rem 0.5rem;
            border-color: $input-border-color;
            border-width: $border-width;

            .multiselect__tags-wrap {
                .multiselect__tag {
                    margin-right: 10px;
                    margin-bottom: 0;
                    font-size: inherit;
                    white-space: normal;
                }
            }

            .multiselect__placeholder {
                padding-top: 0;
                margin-bottom: 0;
                color: $gray-500;
            }

            .multiselect__input {
                padding-left: 0;
                margin: 0;

                &::placeholder {
                    color: $gray-500;
                }
            }

            .multiselect__single {
                margin-bottom: 0;
                font-size: 0.875rem !important;
            }
        }

        &__content-wrapper {
            border: none;
            border-radius: $border-radius;
            @include media-breakpoint-down(sm) {
                max-height: 240px !important;
            }

            .multiselect__content {
                .multiselect__element {
                    .multiselect__option {
                        color: $body-color;
                        white-space: normal;

                        &--selected {
                            font-weight: 500;
                            background: $gray-200 !important;
                        }

                        &--highlight {
                            color: $body-color;
                            background: lighten($primary, 55%);
                        }
                    }
                }
            }
        }

        &.no-dropdown .multiselect__content-wrapper {
            .multiselect__content {
                li:not(.multiselect__element) {
                    display: none;
                }
            }
        }

        &__custom-select {
            position: absolute;
            right: 1px;
            width: 40px;
            height: 38px;
            padding: 4px 8px;
            text-align: center;

            .fd-chevron-down {
                transition: all 0.2s ease;
            }
        }

        &.multiselect--active {
            z-index: 101;

            .multiselect__custom-select .fd-chevron-down {
                transform: translate(-50%, -50%) rotate(180deg);
            }

            .after-list-wrapper {
                width: 100%;
                display: flex;
            }
        }
    }
}

.max-w-25px {
    max-width: 25px;
}
</style>
