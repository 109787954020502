import { render, staticRenderFns } from "./CustomizableViewLoader.vue?vue&type=template&id=1696e9de&scoped=true"
import script from "./CustomizableViewLoader.vue?vue&type=script&lang=js"
export * from "./CustomizableViewLoader.vue?vue&type=script&lang=js"
import style0 from "./CustomizableViewLoader.vue?vue&type=style&index=0&id=1696e9de&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1696e9de",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2024-09-30-115633/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1696e9de')) {
      api.createRecord('1696e9de', component.options)
    } else {
      api.reload('1696e9de', component.options)
    }
    module.hot.accept("./CustomizableViewLoader.vue?vue&type=template&id=1696e9de&scoped=true", function () {
      api.rerender('1696e9de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/CustomizableViewLoader/CustomizableViewLoader.vue"
export default component.exports