var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DoubleConfirmationButton", {
    ref: "double-confirm",
    attrs: { "no-confirm": _vm.noConfirm, order: _vm.order },
    on: {
      submit: function ($event) {
        return _vm.$emit("delete")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "button",
        fn: function ({ click }) {
          return [
            _c(
              "div",
              {
                staticClass: "delete cursor-pointer d-none-for-print",
                class: {
                  "d-none": _vm.modalOnly,
                  "p-2": !_vm.label && !_vm.inputAppend,
                  "input-group-append": _vm.inputAppend,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return click.apply(null, arguments)
                  },
                },
              },
              [
                _vm.isButton
                  ? _c("button", { staticClass: "btn btn-link" }, [
                      _c("i", { staticClass: "icon-green fd-trash" }),
                    ])
                  : !_vm.modalOnly
                  ? _c(
                      "span",
                      {
                        staticClass: "d-flex align-items-center",
                        class: { "input-group-text h-100": _vm.inputAppend },
                      },
                      [
                        !_vm.noIcon
                          ? _c("i", { staticClass: "icon-green fd-trash" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.label
                          ? _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t(_vm.label))),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }