var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offset-md-3 col-md-6 mt-5" },
    [
      _c("ChangePassword"),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom my-4" }),
      _vm._v(" "),
      _c("ChangeName"),
      _vm._v(" "),
      _c("div", { staticClass: "row border-bottom my-4" }),
      _vm._v(" "),
      _c("ChangeLanguage"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }