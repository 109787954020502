var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseBadge", {
    staticClass: "new-badge",
    attrs: { value: _vm.$t("new") },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }