import Vue from 'vue';
import Vuex from 'vuex';

import audits from '@views/Audits/store';
import register from '@views/Auth/views/Register/store';

import activityLog from './activity-log';
import auth from './auth';
import billing from './billing';
import classifiers from './classifiers';
import documents from './documents';
import equipment from './equipment';
import files from './files';
import global from './global';
import common from './modules/common';
import companies from './modules/company';
import { INIT_COMPANIES } from './modules/company/constants';
import loader from './modules/loader';
import localArchive from './modules/local-archive';
import localization from './modules/localization';
import notifications from './modules/notifications';
import overview from './modules/overview';
import permission from './modules/permission';
import profile from './modules/profile';
import storage from './modules/storage';
import userGroups from './modules/user-groups';
import onboardingProfile from './onboarding-profile';
import pauseMonitoring from './pause-monitoring';
import plan from './plan';
import roles from './roles';
import rooms from './rooms';
import sensor from './sensor';
import settings from './settings';
import sharedAccess from './shared-access';
import tasks from './tasks';
import team from './team';
import tickets from './tickets';
import users from './users';

Vue.use(Vuex);

export default new Vuex.Store({
    actions: {
        async initApp({ dispatch }) {
            return {
                companies: await dispatch(INIT_COMPANIES),
            };
        },
    },
    modules: {
        auth,
        audits,
        billing,
        documents,
        companies,
        common,
        profile,
        plan,
        global,
        loader,
        localArchive,
        localization,
        'pause-monitoring': pauseMonitoring,
        notifications,
        overview,
        permission,
        register,
        roles,
        rooms,
        storage,
        tasks,
        team,
        files,
        userGroups,
        classifiers,
        equipment,
        activityLog,
        settings,
        sensor,
        tickets,
        'shared-access': sharedAccess,
        users,
        onboardingProfile,
    },
    // strict: process.env.NODE_ENV !== 'production',
});
