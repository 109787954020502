import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';

import BaseBadges from '@components/BaseBadges';
import BaseCheckbox from '@components/BaseCheckbox';
import BaseDelete from '@components/BaseDelete';
import BaseInput from '@components/BaseInput';
import BaseModal from '@components/BaseModal';
import BaseRadio from '@components/BaseRadio';
import BaseTable from '@components/BaseTable/BaseTable.vue';
import Collapse from '@components/Collapse';
import InvalidIndicator from '@components/InvalidIndicator';
import BreadcrumbMenu from '@components/layout/BreadcrumbMenu';
import IntroductionBody from '@components/layout/IntroductionBody';
import Marker from '@components/Marker';
import MultilingualInput from '@components/MultilingualInput';
import MultilingualQuill from '@components/MultilingualQuill';
import MultilingualTextarea from '@components/MultilingualTextarea';
import ProductionUnits from '@components/ProductionUnits';

// vue components
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('MultilingualTextarea', MultilingualTextarea);
Vue.component('MultilingualInput', MultilingualInput);
Vue.component('MultilingualQuill', MultilingualQuill);
Vue.component('IntroductionBody', IntroductionBody);
Vue.component('InvalidIndicator', InvalidIndicator);
Vue.component('BreadcrumbMenu', BreadcrumbMenu);
Vue.component('ProductionUnits', ProductionUnits);
Vue.component('Collapse', Collapse);
Vue.component('FdMarker', Marker);

Vue.component('BaseBadges', BaseBadges);
Vue.component('BaseDelete', BaseDelete);
Vue.component('BaseModal', BaseModal);
Vue.component('BaseRadio', BaseRadio);
Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseTable', BaseTable);
