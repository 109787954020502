var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AccessLevel", {
    attrs: { "disable-for": ["company-readonly"] },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ denied }) {
          return [
            _c("BaseForm", { on: { submit: _vm.onSubmit } }, [
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "country" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Country")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseSelect",
                      {
                        attrs: {
                          id: "country",
                          options: _vm.countries,
                          rules: "required",
                          "is-editable": !denied && _vm.isEditMode,
                          "custom-label": (item) => item.name,
                          placeholder: _vm.$t("Choose country"),
                          searchable: "",
                          "not-empty": "",
                        },
                        model: {
                          value: _vm.data.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "country", $$v)
                          },
                          expression: "data.country",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.data.country
                                ? _vm.$t(_vm.data.country.name)
                                : "-"
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.hasStates
                ? _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-md-5 col-form-label",
                          attrs: { for: "states" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("States")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c(
                            "BaseSelect",
                            {
                              attrs: {
                                id: "state",
                                options: _vm.countryStates,
                                rules: "required",
                                "is-editable": !denied && _vm.isEditMode,
                                "custom-label": (item) => item.name,
                                placeholder: _vm.$t("Choose state"),
                                searchable: "",
                                "not-empty": "",
                              },
                              model: {
                                value: _vm.data.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "state", $$v)
                                },
                                expression: "data.state",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.data.state
                                      ? _vm.$t(_vm.data.state.name)
                                      : "-"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "company_name" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Company name")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseInput",
                      {
                        attrs: {
                          id: "company_name",
                          "is-editable": !denied && _vm.isEditMode,
                          rules: "required",
                        },
                        model: {
                          value: _vm.data.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "name", $$v)
                          },
                          expression: "data.name",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.data.name ? _vm.data.name : "-") +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.companyIsNotUsa
                ? _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-md-5 col-form-label",
                          attrs: { for: "registration_code" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("registry_code")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c(
                            "BaseInput",
                            {
                              attrs: {
                                id: "registration_code",
                                "is-editable": !denied && _vm.isEditMode,
                                rules: _vm.regCodeRequired,
                              },
                              model: {
                                value: _vm.data.regCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "regCode", $$v)
                                },
                                expression: "data.regCode",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.data.regCode ? _vm.data.regCode : "-"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "vat_code" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("vat_code")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseInput",
                      {
                        attrs: {
                          id: "vat_code",
                          rules: _vm.isEuCountries
                            ? { regex: _vm.vatCodeRegex }
                            : "",
                          placeholder: _vm.vatPlaceholder,
                          uppercase: "",
                          "is-editable": !denied && _vm.isEditMode,
                        },
                        model: {
                          value: _vm.data.vatCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "vatCode", $$v)
                          },
                          expression: "data.vatCode",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.data.vatCode ? _vm.data.vatCode : "-") +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "address" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("address")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseInput",
                      {
                        attrs: {
                          id: "address",
                          rules: "required",
                          "is-editable": !denied && _vm.isEditMode,
                        },
                        model: {
                          value: _vm.data.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "address", $$v)
                          },
                          expression: "data.address",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.data.address ? _vm.data.address : "-") +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "address" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("email_address")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseInput",
                      {
                        attrs: {
                          id: "email",
                          rules: "required|email",
                          "is-editable": !denied && _vm.isEditMode,
                        },
                        model: {
                          value: _vm.data.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "email", $$v)
                          },
                          expression: "data.email",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.data.email ? _vm.data.email : "-") +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "preferred-language" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Preferred language")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseSelect",
                      {
                        attrs: {
                          id: "preferred-language",
                          options: _vm.availableLanguages,
                          "custom-label": (item) => item.name,
                          placeholder: _vm.$t("Choose preferred language"),
                          "not-empty": "",
                          "is-editable": !denied && _vm.isEditMode,
                        },
                        model: {
                          value: _vm.data.preferredLanguage,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "preferredLanguage", $$v)
                          },
                          expression: "data.preferredLanguage",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.data.preferredLanguage
                                ? _vm.$t(_vm.data.preferredLanguage.name)
                                : "-"
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "volume-system" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Volume system")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseSelect",
                      {
                        attrs: {
                          id: "volume-system",
                          "is-editable": !denied && _vm.isEditMode,
                          options: _vm.measurementSystems,
                          "custom-label": (item) => _vm.$t(item.name),
                          placeholder: _vm.$t("Choose volume system"),
                          "not-empty": "",
                        },
                        model: {
                          value: _vm.data.volumeSystem,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "volumeSystem", $$v)
                          },
                          expression: "data.volumeSystem",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.data.volumeSystem
                                ? _vm.$t(_vm.data.volumeSystem.name)
                                : "-"
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "weight-system" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Weight system")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseSelect",
                      {
                        attrs: {
                          id: "weight-system",
                          options: _vm.measurementSystems,
                          "custom-label": (item) => _vm.$t(item.name),
                          placeholder: _vm.$t("Choose weight system"),
                          "is-editable": !denied && _vm.isEditMode,
                          "not-empty": "",
                        },
                        model: {
                          value: _vm.data.weightSystem,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "weightSystem", $$v)
                          },
                          expression: "data.weightSystem",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.data.weightSystem
                                ? _vm.$t(_vm.data.weightSystem.name)
                                : "-"
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-md-5 col-form-label",
                    attrs: { for: "temp-system" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Temperature system")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseSelect",
                      {
                        attrs: {
                          id: "temp-system",
                          options: _vm.tempScales,
                          "custom-label": (item) => _vm.$t(item.name),
                          placeholder: _vm.$t("Choose temperature system"),
                          "is-editable": !denied && _vm.isEditMode,
                          "not-empty": "",
                        },
                        model: {
                          value: _vm.data.tempScale,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "tempScale", $$v)
                          },
                          expression: "data.tempScale",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.data.tempScale
                                ? _vm.$t(_vm.data.tempScale.name)
                                : "-"
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-5 col-form-label",
                    attrs: { for: "entry-log" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Entry log")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "BaseSelect",
                      {
                        staticClass: "w-100",
                        attrs: {
                          id: "entry-log",
                          rules: "required",
                          options: _vm.entryLogOptions,
                          "custom-label": (i) => _vm.$t(i),
                          "not-empty": "",
                          "is-editable": !denied && _vm.isEditMode,
                        },
                        model: {
                          value: _vm.entryLog,
                          callback: function ($$v) {
                            _vm.entryLog = $$v
                          },
                          expression: "entryLog",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.entryLog ? _vm.$t(_vm.entryLog) : "-") +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "col-lg-5 col-form-label d-flex align-items-center",
                    attrs: { for: "temp-prefill" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Pre-filling solution")) +
                        "\n                "
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "fd-info font-size-sm cursor-pointer ml-1",
                      attrs: {
                        title: _vm.$t(
                          "This requires you to confirm the numbers or change them"
                        ),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c("BaseSelect", {
                      staticClass: "w-100",
                      attrs: {
                        id: "temp-prefill",
                        rules: "required",
                        options: _vm.temperaturePredictionsOptions,
                        "custom-label": (i) => _vm.$t(i),
                        "is-editable": !denied && _vm.isEditMode,
                        for: "entry-log",
                      },
                      model: {
                        value: _vm.tempPrefill,
                        callback: function ($$v) {
                          _vm.tempPrefill = $$v
                        },
                        expression: "tempPrefill",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-5 col-form-label",
                    attrs: { for: "entry-log" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Date format")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c("BaseSelect", {
                      staticClass: "w-100",
                      attrs: {
                        id: "entry-log",
                        rules: "required",
                        options: _vm.dateFormats,
                        "custom-label": (format) => _vm.getDateInFormat(format),
                        "not-empty": "",
                        "is-editable": !denied && _vm.isEditMode,
                        "value-as": "id",
                        "custom-to-string": _vm.currentDateFormat,
                      },
                      model: {
                        value: _vm.data.dateFormat,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "dateFormat", $$v)
                        },
                        expression: "data.dateFormat",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row align-items-center" }, [
                !denied
                  ? _c(
                      "div",
                      {
                        staticClass: "col-sm-7 offset-md-5 space-related-out-y",
                      },
                      [
                        _vm.isEditMode
                          ? _c("span", { staticClass: "d-flex" }, [
                              !_vm.hideCancel
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary w-50 space-related-out-r",
                                      on: { click: _vm.stopEditMode },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.$t("Cancel")) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  class: !_vm.hideCancel
                                    ? "space-related-out-l w-50"
                                    : "w-100",
                                  attrs: {
                                    id: "company-creation-save",
                                    type: "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.$t("save")) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ])
                          : _vm.isOwner && !_vm.isEditMode
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-block",
                                attrs: {
                                  id: "company-creation-edit",
                                  type: "button",
                                },
                                on: { click: _vm.startEditMode },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("edit")) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }