var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "marker-wrapper" }, [
    _vm.variant
      ? _c(
          "span",
          {
            class: `text-${_vm.variant} d-flex align-items-center`,
            attrs: { title: _vm.hint },
          },
          [_c("i", { staticClass: "fd-marker font-size-xs mr-1" })]
        )
      : _c("span", {
          staticClass: "marker d-inline-block",
          class: _vm.isSuccess ? "bg-success" : "bg-danger",
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }