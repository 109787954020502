var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModal", {
    ref: "priceModal",
    attrs: {
      size: "md",
      "modal-class": "baseModal",
      "footer-class": "modalFooter",
      "hide-header": "",
      centered: "",
    },
    on: { submit: _vm.submit, cancel: _vm.cancel },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("h1", [_vm._v(_vm._s(_vm.$t("affect_pricing_title")))]),
            _vm._v(" "),
            _c("div", [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Adding a business unit will increase your")) +
                    "\n                " +
                    _vm._s(
                      _vm.quantityChange.recurring_interval === "year"
                        ? _vm.$t("yearly payment by")
                        : _vm.$t("monthly payment by")
                    ) +
                    "\n                " +
                    _vm._s(_vm.quantityChange.recurring_amount_change) +
                    "\n                "
                ),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.quantityChange.currency)),
                ]),
                _vm._v(" " + _vm._s(_vm.$t("VAT")) + ".\n            "),
              ]),
              _vm._v(" "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$t("Please note you will be charged")) +
                    " " +
                    _vm._s(_vm.quantityChange.amount_charged_now) +
                    "\n                " +
                    _vm._s(_vm.$t(_vm.quantityChange.currency).toUpperCase()) +
                    "\n                " +
                    _vm._s(
                      _vm.$t(
                        "VAT today for the period up to your next billing date"
                      )
                    ) +
                    "."
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function ({ cancel, submit }) {
          return [
            _c("span", [
              _c(
                "div",
                {
                  staticClass:
                    "text-center d-flex align-items-center justify-content-center",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("Cancel")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-primary", on: { click: submit } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("create")) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }