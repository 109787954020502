var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "offset-md-5 col-md-7 text-center" }, [
      _c("h2", [_vm._v(_vm._s(_vm.$t("Change language")))]),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "col-12",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.save.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-group row" }, [
          _c(
            "label",
            {
              staticClass: "col-md-5 col-form-label text-md-right text-sm-left",
              attrs: { for: "language" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("Language")) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-7" },
            [
              _c("BaseSelect", {
                attrs: {
                  id: "preferred-language",
                  options: _vm.languages,
                  "custom-label": (item) => item.text,
                  placeholder: _vm.$t("Choose preferred language"),
                  "not-empty": "",
                },
                model: {
                  value: _vm.selectedLanguage,
                  callback: function ($$v) {
                    _vm.selectedLanguage = $$v
                  },
                  expression: "selectedLanguage",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group row" }, [
          _c("div", { staticClass: "offset-md-5 col-md-7" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block",
                attrs: { type: "submit" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("save")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }