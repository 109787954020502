var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationObserver", {
    ref: "form",
    attrs: { name: _vm.name, vid: _vm.name, tag: "form" },
    on: {
      submit: function ($event) {
        $event.preventDefault()
        return _vm.onSubmit.apply(null, arguments)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (v) {
            return [_vm._t("default", null, null, v)]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }