var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "files-box border-radius" }, [
    _c(
      "header",
      { staticClass: "files-box_header", on: { click: _vm.toggleCollapse } },
      [
        _vm.progress
          ? _c("ProgressBar", {
              staticClass: "w-100",
              attrs: { show: _vm.isCollapsed, percent: _vm.progress },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isCollapsed
          ? _c("p", { staticClass: "w-100 p-0 m-0" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("Upload progress")) +
                  "\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-icon m-1" }, [
          _c("i", {
            class: `${
              _vm.isCollapsed ? "fd-chevron-up" : "fd-chevron-down"
            } icon-dark`,
          }),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isCollapsed,
            expression: "!isCollapsed",
          },
        ],
      },
      [
        _c(
          "ul",
          { staticClass: "w-100 files-box_list list list-unstyled" },
          _vm._l(_vm.value, function (item) {
            return _c("FileItem", {
              key: item.id,
              staticClass: "item font-size-xs",
              attrs: { tag: "li", value: item },
            })
          }),
          1
        ),
        _vm._v(" "),
        _vm.finishedAll
          ? _c(
              "div",
              {
                staticClass:
                  "text-right w-100 d-flex justify-content-end px-2 pb-2",
              },
              [
                _c(
                  "button",
                  { staticClass: "btn", on: { click: _vm.clickClear } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("clear")) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }