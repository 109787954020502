<template>
    <div class="row">
        <div class="offset-md-5 col-md-7 text-center">
            <h2>{{ $t('changing_password') }}</h2>
        </div>
        <BaseForm
            class="col-12"
            @submit="changePassword"
        >
            <div class="form-group row">
                <label
                    :for="'oldPassword'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('old_password') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="oldPassword"
                        v-model="oldPassword"
                        type="password"
                        rules="required|min:4"
                    >
                        <template #error>
                            {{ $t('Password must be at least 4 characters long') }}
                        </template>
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row">
                <label
                    :for="'newPassword'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('new_password') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="newPassword"
                        v-model.trim="newPassword"
                        name="newPassword"
                        type="password"
                        :rules="{
                            required: true,
                            min: 4,
                            regex: /^[^{}()<>&]{4,40}$/,
                        }"
                    >
                        <template #error>
                            <!-- eslint-disable-next-line -->
                            {{ $t('Password must be at least four letters long and must not contain following characters: {}()<>&') }}
                        </template>
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row">
                <label
                    :for="'confirmPassword'"
                    class="col-md-5 col-form-label text-md-right text-sm-left"
                >
                    {{ $t('repeat_new_password') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="confirmPassword"
                        v-model.trim="confirmPassword"
                        type="password"
                        :rules="{
                            required: true,
                            min: 4,
                            regex: /^[^{}()<>&]{4,40}$/,
                            confirmed: 'newPassword',
                        }"
                    >
                        <template #error>
                            <div v-if="confirmPassword !== newPassword">
                                {{ $t('Passwords did not match') }}
                            </div>
                            <div v-else>
                                <!-- eslint-disable-next-line -->
                                {{ $t('Password must be at least four letters long and must not contain following characters: {}()<>&') }}
                            </div>
                        </template>
                    </BaseInput>
                </div>
            </div>
            <div class="form-group row">
                <div class="offset-md-5 col-md-7">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block"
                    >
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </BaseForm>
    </div>
</template>

<script>
import { mapActions } from 'pinia';

import settingsService from '@services/settings.service';

import { useLoaderStore } from '@store/pinia-utils/loader';

import BaseForm from '@components/BaseForm';

export default {
    components: {
        BaseForm,
    },

    data() {
        return {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        };
    },

    methods: {
        ...mapActions(useLoaderStore, ['setLoading']),
        async changePassword({ validation }) {
            const valid = await validation.validate();
            if (valid) {
                this.setLoading(true);
                const data = {
                    new_password: this.newPassword,
                    old_password: this.oldPassword,
                };
                settingsService
                    .changePassword(data)
                    .then((res) => {
                        if (res) {
                            this.$toastr.s(this.$t('pw_change_success'), this.$t('success') + '!');
                            this.oldPassword = '';
                            this.newPassword = '';
                            this.confirmPassword = '';
                            validation.reset();
                        }
                    })
                    .finally(() => {
                        this.setLoading(false);
                    });
            }
        },
    },
};
</script>
