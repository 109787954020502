var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEditMode
    ? _c("BaseSelect", {
        attrs: {
          options: _vm.timezones,
          "custom-label": _vm.label,
          placeholder: _vm.$t("Time zone"),
          "not-empty": "",
        },
        model: {
          value: _vm.val,
          callback: function ($$v) {
            _vm.val = $$v
          },
          expression: "val",
        },
      })
    : _c("span", [
        _vm.val
          ? _c("span", [
              _vm._v(_vm._s(_vm.val.timezone) + ", " + _vm._s(_vm.val.offset)),
            ])
          : _c("span", [_vm._v("-")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }