var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-flex" },
    [
      _vm._t("button", null, { click: _vm.show }),
      _vm._v(" "),
      _c("BaseModal", {
        ref: "modal",
        attrs: {
          "modal-class": "baseModal",
          "hide-footer": "",
          "hide-header": "",
          order: _vm.order,
          centered: "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [
                  _vm._t(
                    "modal",
                    function () {
                      return [
                        _c("div", [
                          _c("div", { staticClass: "row space-related-in-b" }, [
                            _c("div", { staticClass: "col text-center" }, [
                              _c("h1", [
                                _vm._v(_vm._s(_vm.$t("Confirm delete?"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt-2 space-related-out-t" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("This action cannot be undone")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "space-related-out-t d-flex align-items-center justify-content-center",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-primary mx-1",
                                  on: { click: _vm.hide },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$t("Cancel")) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mx-1",
                                  on: { click: _vm.submit },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$t("delete")) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    { close: _vm.hide, submit: _vm.submit }
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }