import { render, staticRenderFns } from "./SettingUp.vue?vue&type=template&id=acbc65c6&scoped=true"
import script from "./SettingUp.vue?vue&type=script&lang=js"
export * from "./SettingUp.vue?vue&type=script&lang=js"
import style0 from "./SettingUp.vue?vue&type=style&index=0&id=acbc65c6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acbc65c6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2024-09-30-115633/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('acbc65c6')) {
      api.createRecord('acbc65c6', component.options)
    } else {
      api.reload('acbc65c6', component.options)
    }
    module.hot.accept("./SettingUp.vue?vue&type=template&id=acbc65c6&scoped=true", function () {
      api.rerender('acbc65c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Auth/views/Register/SettingUp.vue"
export default component.exports