var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    {
      ref: _vm.component === "a" ? "noopener noreferer" : null,
      tag: "Component",
      staticClass: "btn",
      class: _vm.classList,
      attrs: {
        type: _vm.type,
        disabled: _vm.disabled,
        to: _vm.to,
        href: _vm.href,
        target: _vm.component === "a" ? "_blank" : null,
      },
      on: { click: _vm.onClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }