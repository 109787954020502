var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Transition", { attrs: { name: "slide-from-right-side" } }, [
    _vm.isNotificationsViewOpen
      ? _c(
          "section",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.onClose,
                expression: "onClose",
              },
            ],
            staticClass: "notifications-view shadow-lg bg-white",
            attrs: { "data-cy": "notifications-view" },
          },
          [
            _c(
              "header",
              {
                staticClass:
                  "px-4 py-2 d-flex align-items-center justify-content-between bg-primary text-white",
              },
              [
                _c("h2", { staticClass: "m-0 font-size-lg" }, [
                  _vm._v(_vm._s(_vm.$t(`What is new`))),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link text-white",
                    attrs: { type: "button" },
                    on: { click: _vm.onClose },
                  },
                  [_c("i", { staticClass: "fd-close font-size-lg" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "px-4 pb-2 content-view" },
              _vm._l(_vm.sortedNotifications, function (notification) {
                return _c(
                  "article",
                  {
                    key: notification.id,
                    staticClass: "pb-3 pt-3 border-bottom",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c("div", { staticClass: "col-2 p-0" }, [
                          _c(
                            "figure",
                            { staticClass: "notification-img-box" },
                            [
                              _c("img", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  src: notification.preview_file_name,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-10 pr-0" }, [
                          _c("h3", { staticClass: "text-muted mb-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$filters.localeOrSomething(
                                  notification.title
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "text-primary mx-y",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getIntro(notification.intro)
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "time",
                            {
                              staticClass: "text-muted mt-2 d-block",
                              attrs: { datetime: notification.update_at },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(notification.updated_at) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }