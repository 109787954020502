var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Portal",
    { staticClass: "bg-gray-200", attrs: { to: "sidemenu", order: _vm.order } },
    [
      _c(
        "div",
        {
          staticClass: "sidemenu-wrapper bg-gray-200",
          class: {
            "after-menu-floating": _vm.afterMenuFloating,
            "padding-top-sidemenu": _vm.paddingTop,
          },
        },
        [
          !_vm.isShown && !_vm.hideToggle
            ? _c(
                "button",
                {
                  staticClass: "sidemenu-action-btn btn btn-icon",
                  class: { active: _vm.isShown, "btn-dark": !_vm.isShown },
                  on: {
                    click: function ($event) {
                      _vm.isShown = true
                    },
                  },
                },
                [_c("i", { staticClass: "fd-chevron-right" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "sidemenu-inner-wrapper bg-gray-200",
              class: {
                "after-menu-floating": _vm.afterMenuFloating,
              },
            },
            [
              _c(
                "div",
                {
                  ref: "sidemenu",
                  staticClass: "sidemenu bg-gray-200",
                  class: {
                    active: _vm.isShown,
                    "after-menu-floating": _vm.afterMenuFloating,
                    "haccp-sidemenu": _vm.isHaccpSidemenu,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "sidemenu-content overflow-y-scroll bg-gray-200 h-100",
                      class: {
                        active: _vm.isShown,
                        "after-menu-floating": _vm.afterMenuFloating,
                      },
                    },
                    [
                      _vm.showHeader
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "sidemenu-nav align-items-end d-lg-none align-items-center bg-gray-200",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between",
                                },
                                [
                                  !_vm.$store.getters[
                                    "shared-access/isSharedAccess"
                                  ]
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-link",
                                          on: { click: _vm.back },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fd-chevron-left text-light thin-icon",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "my-auto" }, [
                                    _vm._v(_vm._s(_vm.$t(_vm.title))),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btb-link",
                                      on: { click: _vm.close },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fd-close text-light thin-icon",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "scroll-sidemenu-list px-0 mx-0",
                          class: _vm.showHeader && "showHeader",
                          on: { scroll: _vm.handleScroll },
                        },
                        _vm._l(_vm.items, function (item, index) {
                          return _c("Item", {
                            key: index,
                            attrs: {
                              id: `sidemenu-item-${index}-${
                                item.to &&
                                item.to.name &&
                                item.to.name.replace(".", "-")
                              }`,
                              "custom-label": _vm.customLabel,
                              "has-nested": _vm.hasNested,
                              index: index,
                              value: item,
                            },
                            on: {
                              click: _vm.close,
                              "toggle-visibility": _vm.checkIsMenuScrollable,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "before-item",
                                  fn: function (attr) {
                                    return [
                                      _vm._t("before-item", null, null, attr),
                                    ]
                                  },
                                },
                                {
                                  key: "after-item",
                                  fn: function (attr) {
                                    return [
                                      _vm._t("after-item", null, null, attr),
                                      _vm._v(" "),
                                      item.hasAttentionMarker
                                        ? _c("span", {
                                            staticClass:
                                              "attention-marker-red ml-2",
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasAfterMenu
                    ? _c(
                        "div",
                        {
                          staticClass: "sidemenu-after-menu",
                          class: {
                            "after-menu-floating": _vm.afterMenuFloating,
                          },
                        },
                        [_vm._t("after-menu")],
                        2
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              !_vm.disableScrollIndicator &&
              _vm.isMenuScrollable &&
              !_vm.scrolledToEnd
                ? _c("div", { staticClass: "sidemenu-scroll" }, [
                    _c("i", { staticClass: "fd-arrows-down" }),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }