var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container d-flex align-items-center justify-content-center",
    },
    [_c("CompanyForm", { staticClass: "w-50", on: { submit: _vm.submit } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }