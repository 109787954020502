<template>
    <AccessLevel
        v-slot="{ denied }"
        :disable-for="['company-readonly']"
    >
        <BaseForm
            data-cy="place-form"
            @submit="onSubmit"
        >
            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="name"
                    >{{ $t('Food handling place name') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="name"
                        v-model="data.name"
                        :is-editable="!denied && isEditMode"
                        data-cy="name-field"
                        rules="required"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="address"
                    >{{ $t('address') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="address"
                        v-model="data.address"
                        :is-editable="!denied && isEditMode"
                        data-cy="address-field"
                        rules="required"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="country"
                    >{{ $t('Country') }}</label
                >
                <div class="col-md-7">
                    <BaseSelect
                        id="country"
                        v-model="data.country"
                        rules="required"
                        :is-editable="!denied && isEditMode"
                        data-cy="country-field"
                        :options="countries"
                        :custom-label="(item) => $t(item.name)"
                        :placeholder="$t('Choose country')"
                        searchable
                        not-empty
                    />
                </div>
            </div>

            <div
                v-if="hasStates"
                class="form-group row align-items-center"
            >
                <label
                    class="col-form-label col-md-5"
                    for="state"
                    >{{ $t('State') }}</label
                >
                <div class="col-md-7">
                    <BaseSelect
                        id="state"
                        v-model="data.state"
                        rules="required"
                        :is-editable="!denied && isEditMode"
                        data-cy="state-field"
                        :options="countryStates"
                        :custom-label="(item) => $t(item.name)"
                        :placeholder="$t('Choose state')"
                        searchable
                        not-empty
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="phone"
                    >{{ $t('Phone') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="phone"
                        v-model="data.phone"
                        :is-editable="!denied && isEditMode"
                        rules="required"
                        data-cy="phone-field"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="email"
                    >{{ $t('email') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="email"
                        v-model="data.email"
                        :is-editable="!denied && isEditMode"
                        data-cy="email-field"
                        rules="required|email"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5 d-flex align-items-center"
                    for="notification-email"
                >
                    {{ $t('notification_email') }}
                    <i
                        v-b-tooltip.hover
                        class="fd-info cursor-pointer ml-1"
                        :title="$t('Add Multiple emails by using comma(,) as separation character')"
                    ></i>
                </label>
                <div class="col-md-7">
                    <BaseInput
                        id="notification-email"
                        v-model="data.notificationEmail"
                        :is-editable="!denied && isEditMode"
                        data-cy="notification-email-field"
                        rules="email"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="representative-person"
                    >{{ $t('representative') }}</label
                >
                <div class="col-md-7">
                    <BaseInput
                        id="representative-person"
                        v-model="data.representativePerson"
                        :is-editable="!denied && isEditMode"
                        rules="required"
                        data-cy="representative-person-field"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="main-activity-category"
                    >{{ $t('business_activity') }}</label
                >
                <div class="col-md-7">
                    <BaseSelect
                        id="main-activity-category"
                        v-model="businessActivity"
                        :is-editable="!denied && isEditMode"
                        rules="required"
                        :options="businessActivities"
                        :custom-label="(item) => $t(item.name)"
                        not-empty
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="food-handling-place"
                    >{{ $t('food_handling_place') }}</label
                >
                <div class="col-md-7">
                    <BaseSelect
                        id="food-handling-place"
                        v-model="data.locationType"
                        :is-editable="!denied && isEditMode"
                        rules="required"
                        data-cy="location-type-field"
                        :options="locationTypes"
                        :custom-label="(item) => $t(item.name)"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="number-of-employees"
                    >{{ $t('number_of_employees') }}</label
                >
                <div class="col-md-7">
                    <BaseSelect
                        id="number-of-employees"
                        v-model="data.numberOfEmployees"
                        :is-editable="!denied && isEditMode"
                        rules="required"
                        data-cy="number-of-employees-field"
                        :options="employeeNumbersOptions"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="preferred-language"
                    >{{ $t('Preferred language') }}</label
                >
                <div class="col-md-7">
                    <BaseSelect
                        id="preferred-language"
                        v-model="data.preferredLanguage"
                        :is-editable="!denied && isEditMode"
                        :options="availableLanguages"
                        :custom-label="(item) => item.name"
                        :placeholder="$t('Choose preferred language')"
                        not-empty
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-form-label col-md-5"
                    for="preferred-language"
                    >{{ $t('Time zone') }}</label
                >
                <div class="col-md-7">
                    <TimezoneSelect
                        v-model="data.timezone"
                        :is-edit-mode="!denied && isEditMode"
                    />
                </div>
            </div>

            <slot></slot>

            <div class="form-group row align-items-center">
                <div
                    v-if="!denied"
                    class="col-sm-7 offset-md-5 space-related-out-b"
                >
                    <span
                        v-if="isEditMode"
                        class="d-flex"
                    >
                        <button
                            v-if="!hideCancel"
                            class="btn btn-secondary w-50 space-related-out-r"
                            @click="stopEditMode"
                        >
                            {{ $t('Cancel') }}
                        </button>
                        <button
                            id="company-creation-save"
                            type="submit"
                            class="btn btn-primary"
                            :class="!hideCancel ? 'space-related-out-l w-50' : 'w-100'"
                        >
                            {{ $t('save') }}
                        </button>
                    </span>
                    <button
                        v-else-if="!isEditMode"
                        id="company-creation-edit"
                        type="button"
                        class="btn btn-primary btn-block"
                        @click="startEditMode"
                    >
                        {{ $t('edit') }}
                    </button>
                </div>
            </div>
        </BaseForm>
    </AccessLevel>
</template>

<script>
import { mapGetters } from 'vuex';

import { COUNTRIES } from '@common/constants';

import AccessLevel from '@components/AccessLevel';
import BaseForm from '@components/BaseForm';

import TimezoneSelect from '@views/SetUp/components/TimezoneSelect';

export default {
    name: 'PlaceForm',

    components: {
        TimezoneSelect,
        AccessLevel,
        BaseForm,
    },

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        isEditMode: {
            type: Boolean,
            default: false,
        },
        hideCancel: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['submit'],

    data() {
        return {
            data: {
                name: '',
                country: '',
                state: null,
                regCode: '',
                address: '',
                phone: '',
                email: '',
                notificationEmail: '',
                representativePerson: '',
                businessActivityValue: null,
                locationType: null,
                terrace: false,
                numberOfEmployees: null,
                preferredLanguage: null,
                timezone: null,
            },
        };
    },

    computed: {
        ...mapGetters([
            'countries',
            'businessActivities',
            'selectedCompany',
            'availableLanguages',
            'canWritePlace',
            'countryStates',
        ]),
        businessActivity: {
            get() {
                if (!this.data.businessActivityValue) {
                    return this.businessActivities.find(({ id }) => id === this.value?.business_activity?.id);
                }

                return this.data.businessActivityValue;
            },
            set(value) {
                this.data.businessActivityValue = value;
                this.data.locationType = null;
            },
        },
        locationTypes() {
            return this.businessActivity?.location_types || [];
        },
        employeeNumbersOptions() {
            return ['1-3', '4-14', '15+'];
        },
        country() {
            return this.data.country;
        },
        hasStates() {
            return !!this.countryStates?.length;
        },
    },

    watch: {
        country: async function (val) {
            if (val !== null) {
                await this.$store.dispatch('setCountryStates', val.id);
            }
        },
        value: {
            immediate: true,
            async handler(value) {
                if (value) {
                    await this.$store.dispatch('setCountryStates', value?.country_id);
                    this.data.name = value.name;
                    this.data.address = value.address;
                    this.data.phone = value.person_phone;
                    this.data.email = value.person_email;
                    this.data.notificationEmail = value.notification_email;
                    this.data.representativePerson = value.contact_person;
                    this.data.locationType = value.location_type;
                    this.data.numberOfEmployees = value.number_of_employees;
                    this.data.timezone = value.timezone;

                    if (value.preferred_language) {
                        this.data.preferredLanguage = this.availableLanguages.find(
                            (item) => item.key === value.preferred_language
                        );
                    } else {
                        this.data.preferredLanguage = this.availableLanguages.find(
                            (item) => item.key === this.$i18n.locale
                        );
                    }
                    if (value?.state_id) {
                        this.data.state = this.countryStates.find((item) => item.id === value.state_id);
                    }
                    if (value?.country_id) {
                        this.data.country = this.countries.find((item) => item.id === value.country_id);
                    } else {
                        this.data.country = this.countries.find((item) => item.id === COUNTRIES.ESTONIA);
                    }
                }
            },
        },
    },

    created() {
        let countryId = COUNTRIES.ESTONIA;

        if (this.selectedCompany?.company?.country_id) {
            countryId = this.selectedCompany.company.country_id;
        }

        if (!this.data.country) {
            this.data.country = this.countries.find((item) => item.id === countryId);
        }
    },

    methods: {
        async onSubmit(e) {
            const valid = await e.validation.validate();

            if (valid) {
                const { businessActivity } = this;

                this.$emit('submit', {
                    values: {
                        ...this.data,
                        businessActivity,
                    },
                    e,
                });
                this.stopEditMode();
            } else {
                // TODO: Scroll to the error
                this.$toastr.e(this.$t('Mandatory fields not filled'));
            }
        },
        startEditMode() {
            this.isEditMode = true;
        },
        stopEditMode() {
            this.isEditMode = false;
        },
        async getSelectedCountryStates(countryId) {
            this.$store.dispatch('setCountryStates', countryId);
        },
    },
};
</script>
