var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand-lg navbar-light justify-content-between register-header",
    },
    [
      _c(
        "div",
        { staticClass: "d-flex register-logo align-items-center" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [_c("Logo", { attrs: { responsive: "" } })],
            1
          ),
          _vm._v(" "),
          _c("LanguageSwitcher", {
            staticClass: "pl-2 mr-auto",
            attrs: { position: "position-absolute" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "collapse navbar-collapse",
          attrs: { id: "topRegisterNav" },
        },
        [
          _c("div", { staticClass: "my-2 ml-auto dropdown-menu-left" }, [
            _c("div", { staticClass: "d-flex justify-content-end" }, [
              _c("div", { staticClass: "mr-3" }, [
                _c("div", { staticClass: "btn-group" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "d-flex cursor-pointer user-logo align-items-center",
                      attrs: {
                        id: "profileRegister",
                        "aria-expanded": "false",
                        "aria-haspopup": "true",
                        "data-toggle": "dropdown",
                      },
                    },
                    [
                      _c("i", { staticClass: "fd-user-circle mr-1" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-dark mr-1",
                          attrs: { id: "profileRegister-name" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.getUserDisplayName) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("i", { staticClass: "fd-chevron-down font-size-sm" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu dropdown-menu-right" },
                    [
                      !_vm.companyExist.length ||
                      (!_vm.placeExist.length && !_vm.newCompanyPlace)
                        ? _c(
                            "button",
                            {
                              staticClass: "dropdown-item",
                              attrs: { type: "button" },
                              on: { click: _vm.logout },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("log_out")) +
                                  "\n                                "
                              ),
                              _c("i", { staticClass: "fd-sign-out p-2" }),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "dropdown-item",
                              attrs: { type: "button" },
                              on: { click: _vm.backToSettings },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("close")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
          "data-target": "#topRegisterNav",
          "data-toggle": "collapse",
          type: "button",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }