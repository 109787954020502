var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ViewLoader"),
      _vm._v(" "),
      _c("RouterView"),
      _vm._v(" "),
      _c("NeedRefreshModal", { ref: "newRelease" }),
      _vm._v(" "),
      _vm.backgroundUploadingFiles.length
        ? _c("FileLoaderBox", {
            staticClass: "file-loader-box",
            attrs: { value: _vm.backgroundUploadingFiles },
          })
        : _vm._e(),
      _vm._v(" "),
      [
        _c("NotificationsView"),
        _vm._v(" "),
        _c("AffectPricingNotificationModal"),
        _vm._v(" "),
        _c(
          "NeedToPayNotification",
          {
            ref: "subscriptionModal",
            on: {
              cancel: _vm.redirectToOverview,
              submit: _vm.redirectToBilling,
            },
          },
          [
            _c("h1", [_vm._v(_vm._s(_vm.$t("Your subscription has ended.")))]),
            _vm._v(" "),
            _c("h2", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "Please subscribe to our service to continue using FoodDocs. Hope to see you soon!"
                  )
                )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "NeedToPayNotification",
          {
            ref: "trialModal",
            on: {
              cancel: _vm.redirectToOverview,
              submit: _vm.redirectToBilling,
            },
          },
          [
            _c("h1", [_vm._v(_vm._s(_vm.$t("Your free trial has ended.")))]),
            _vm._v(" "),
            _c("h2", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "Please subscribe to our service to continue using FoodDocs. Hope to see you soon!"
                  )
                )
              ),
            ]),
          ]
        ),
      ],
      _vm._v(" "),
      _c("BaseModal", {
        ref: "shared-link-expired-modal",
        attrs: {
          size: "md",
          "modal-class": "baseModal",
          "hide-header": "",
          "hide-footer": "",
          "no-close": "",
          centered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("This link is not valid anymore"))),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.closeExpireLinkModal },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("Close")) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "BaseModal",
        {
          ref: "easy-app-modal",
          attrs: {
            size: "md",
            "modal-class": "baseModal",
            "hide-header": "",
            "hide-footer": "",
            centered: "",
          },
        },
        [
          _c("img", {
            attrs: { src: "/assets/img/easy_app.svg", alt: "Mobile app" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "space-related-out-t text-center" }, [
            _c("h3", { staticClass: "h1" }, [
              _vm._v(_vm._s(_vm.$t("Daily tasks in easy app"))),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t(
                      "All your daily tasks can be filled in on a mobile or tablet, download FoodDocs app on"
                    )
                  ) +
                  "\n                "
              ),
              _c(
                "a",
                {
                  attrs: { target: "_blank", href: _vm.APP_LINKS.GOOGLE_PLAY },
                },
                [_vm._v("Google Play")]
              ),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("or")) +
                  "\n                "
              ),
              _c(
                "a",
                {
                  attrs: { target: "_blank", href: _vm.APP_LINKS.APPLE_STORE },
                },
                [_vm._v("Apple App Store")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-center space-related-out-t",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.closeEasyAppModal },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("Close")) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("PortalTarget", {
        staticClass: "app-modal",
        attrs: { name: "modal", multiple: "" },
      }),
      _vm._v(" "),
      _c("QuestionnaireModal", { ref: "questionnaireModal" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }