export default [
    {
        path: '',
        name: 'traceability',
        redirect: { name: 'traceability.preparation.list' },
        component: () => import('./Index.vue'),
        children: [
            {
                path: 'preparation',
                name: 'traceability.preparation',
                component: () => import('./Traceability/Index.vue'),
                children: [
                    {
                        path: 'records',
                        name: 'traceability.preparation.list',
                        component: () => import('./Traceability/Preparation/PreparationRecordsList.vue'),
                        children: [],
                    },
                    {
                        path: 'records/:id',
                        name: 'traceability.preparation.record-panel',
                        component: () =>
                            import(
                                '@views/TraceabilityModule/Traceability/Preparation/PreparationRecordPanel/Index.vue'
                            ),
                    },
                    {
                        path: 'records/:id',
                        name: 'traceability.records.record-panel',
                        component: () =>
                            import(
                                '@views/TraceabilityModule/Traceability/TraceabilityMonitoring/TraceabilityRecordsPanel/Index.vue'
                            ),
                    },
                ],
            },
            {
                path: 'monitoring',
                name: 'traceability.monitoring',
                redirect: { name: 'traceability.monitoring.tasks' },
                component: () => import('@views/TraceabilityModule/Traceability/TraceabilityMonitoring/Index.vue'),
                children: [
                    {
                        path: 'tasks',
                        name: 'traceability.monitoring.tasks',
                        component: () =>
                            import(
                                '@views/TraceabilityModule/Traceability/TraceabilityMonitoring/Entries/TracedTasksList.vue'
                            ),
                    },
                    {
                        path: 'tasks/:id/versions',
                        name: 'traceability.monitoring.task.task-versions',
                        component: () =>
                            import(
                                '@views/TraceabilityModule/Traceability/TraceabilityMonitoring/Entries/TracedTaskVersions.vue'
                            ),
                    },
                    {
                        path: 'tasks/:id',
                        name: 'traceability.monitoring.task-entries',
                        component: () =>
                            import(
                                '@views/TraceabilityModule/Traceability/TraceabilityMonitoring/Entries/TaskEntriesTable.vue'
                            ),
                    },
                    {
                        path: 'records/:id',
                        name: 'traceability.monitoring.record-panel',
                        component: () =>
                            import(
                                '@views/TraceabilityModule/Traceability/TraceabilityMonitoring/TraceabilityRecordsPanel/Index.vue'
                            ),
                    },
                ],
            },
            {
                path: 'preparation',
                name: 'traceability.preparation.demo',
                component: () => import('./PreparationDemo.vue'),
            },
            {
                path: 'products',
                name: 'traceability.products',
                component: () => import('./Products/Index.vue'),
                children: [
                    {
                        path: 'allergen-matrix',
                        name: 'traceability.products.matrix',
                        component: () => import('./Products/AllergenMatrix/Index.vue'),
                    },
                    {
                        path: 'all',
                        name: 'traceability.products.list',
                        component: () => import('./Products/ProductsList.vue'),
                    },
                    {
                        path: 'details',
                        name: 'traceability.products.details',
                        redirect: { name: 'traceability.products.details.general' },
                        component: () => import('./Products/ProductDetails/Index.vue'),
                        children: [
                            {
                                path: 'general',
                                name: 'traceability.products.details.general',
                                component: () => import('./Products/ProductDetails/ProductGeneralInfo.vue'),
                            },
                            {
                                path: 'production-info',
                                name: 'traceability.products.details.production-info',
                                component: () => import('./Products/ProductDetails/ProductProductionInfo.vue'),
                            },
                            {
                                path: 'consumer-info',
                                name: 'traceability.products.details.consumer-info',
                                component: () => import('./Products/ProductDetails/ProductConsumerInfo.vue'),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'settings',
                name: 'traceability.settings',
                redirect: 'traceability.settings.units',
                component: () => import('./Settings/Index.vue'),
                children: [
                    {
                        path: 'units',
                        name: 'traceability.settings.units',
                        component: () => import('./Settings/CustomMeasuringUnits.vue'),
                    },
                    {
                        name: 'traceability.settings.tasks',
                        path: '',
                        redirect: { name: 'traceability.settings.tasks.list' },
                        component: () => import('./Settings/TraceabilityTasks/Index.vue'),
                        children: [
                            {
                                path: 'tasks',
                                name: 'traceability.settings.tasks.list',
                                component: () => import('./Settings/TraceabilityTasks/TasksList.vue'),
                                children: [
                                    {
                                        path: ':id',
                                        name: 'traceability.settings.tasks.task',
                                        component: () => import('@views/Tasks/panels/TaskPanel/index.vue'),
                                    },
                                    {
                                        path: 'new',
                                        name: 'traceability.settings.tasks.task-new',
                                        component: () => import('@views/Tasks/panels/TaskPanel/index.vue'),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
