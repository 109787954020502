var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "badge badge-pill d-inline-flex align-items-center",
      class: [_vm.variantClass, _vm.badgeType],
    },
    [
      _vm.value && _vm.value.length > 20
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  value: _vm.value,
                  expression: "value",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "text",
            },
            [_vm._v("\n        " + _vm._s(_vm.value) + "\n    ")]
          )
        : _c("span", [_vm._v(_vm._s(_vm.value))]),
      _vm._v(" "),
      _vm.deletable
        ? _c("i", {
            staticClass: "fd-close ml-1 cursor-pointer",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("delete")
              },
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }