var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-view-loader" },
    [
      _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            "no-close": "",
            "no-header": "",
            "no-footer": "",
            "hide-header": "",
            "hide-footer": "",
            centered: "",
          },
        },
        [
          _c("div", { staticClass: "loader-wrapper" }, [
            _c("span", { staticClass: "loader" }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "space-unrelated-out-x text-wrapper text-center d-flex flex-column align-items-center justify-content-center",
            },
            [
              _c("p", { staticClass: "h2" }, [_vm._v(_vm._s(_vm.heading))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.message))]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }