import { render, staticRenderFns } from "./index.vue?vue&type=template&id=225079a2&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=225079a2&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225079a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2024-09-30-115633/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('225079a2')) {
      api.createRecord('225079a2', component.options)
    } else {
      api.reload('225079a2', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=225079a2&scoped=true", function () {
      api.rerender('225079a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/BaseRadio/index.vue"
export default component.exports