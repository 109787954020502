<template>
    <aside
        v-if="display"
        data-cy="info-box"
        :class="{
            fill: boxType === 'fill',
            outline: boxType !== 'fill',
            className,
        }"
        class="alert alert-dismissible fade show info-box d-flex align-items-center space-related-in justify-content-between"
    >
        <div class="d-flex align-items-center">
            <i class="fd-info info-box__icon" />

            <div class="info-box__content">
                <slot class="mb-0"></slot>
            </div>
        </div>
        <Action
            v-if="!noClose"
            data-cy="close-btn"
            class="close-button"
            aria-label="Close"
            theme="icon"
            @click.prevent="onClose"
        >
            <i class="fd-close" />
        </Action>
    </aside>
</template>

<script>
import Action from '@components/Action/Action';

export default {
    name: 'InfoBox',

    components: { Action },

    props: {
        id: {
            type: String,
            required: true,
        },
        boxType: {
            type: String,
            default: 'fill',
        },
        className: {
            type: String,
            default: '',
        },
        noClose: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            display: true,
        };
    },

    created() {
        this.display = !localStorage.getItem(this.id);
    },

    methods: {
        onClose() {
            this.display = false;

            localStorage.setItem(this.id, 'hide');
        },
    },
};
</script>

<style lang="scss" scoped>
.info-box {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    color: $gray-700 !important;
    border-radius: 8px !important;

    &.fill {
        background-color: $light-green !important;
        border-color: $light-green !important;

        &.warning {
            background-color: $yellow-bg !important;
            border-color: $yellow-bg !important;
        }
    }

    &.black {
        border-color: $black !important;
        border-width: 1px;
        background-color: transparent !important;
    }

    &.outline {
        border-color: $green-border !important;
        border-width: 1px;

        i {
            color: $green-border !important;
        }

        &.warning {
            border-color: $yellow-bg !important;

            i {
                color: $yellow-bg !important;
            }
        }
    }

    &::v-deep ul {
        padding-inline-start: 2rem;
        margin: 0 !important;

        li::marker {
            font-size: 0.875rem !important;
        }
    }

    &::v-deep p {
        padding: 0;
        margin: 0 2rem 0 0;
    }

    a {
        text-decoration: underline !important;
    }

    &__icon {
        align-self: center !important;
        margin-right: 1rem !important;
        font-size: 1.5rem !important;
        color: $gray-700;
    }

    .close-button {
        margin-top: auto;
        margin-bottom: auto;

        i {
            color: black;
        }
    }
}
</style>
