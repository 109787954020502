var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "offset-md-5 col-md-7 text-center" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("changing_name")))]),
      ]),
      _vm._v(" "),
      _c(
        "BaseForm",
        { staticClass: "col-12", on: { submit: _vm.changeName } },
        [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass:
                  "col-md-5 col-form-label text-md-right text-sm-left",
                attrs: { for: "firstName" },
              },
              [_vm._v("\n                " + _vm._s(_vm.$t("first_name")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-7" },
              [
                _c("BaseInput", {
                  attrs: { id: "firstName", rules: "required" },
                  model: {
                    value: _vm.firstName,
                    callback: function ($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass:
                  "col-md-5 col-form-label text-md-right text-sm-left",
                attrs: { for: "lastName" },
              },
              [_vm._v("\n                " + _vm._s(_vm.$t("last_name")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-7" },
              [
                _c("BaseInput", {
                  attrs: { id: "lastName", rules: "required" },
                  model: {
                    value: _vm.lastName,
                    callback: function ($$v) {
                      _vm.lastName = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "lastName",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c("div", { staticClass: "offset-md-5 col-md-7" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  attrs: { type: "submit" },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("save")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }