var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: { block: !_vm.isNotDone },
      on: { click: _vm.goToSource },
    },
    [
      _c("span", { staticClass: "w-100 cursor-pointer" }, [
        _vm._v(_vm._s(_vm.value.name)),
      ]),
      _vm._v(" "),
      _vm.isNotDone && !_vm.isError
        ? _c(
            "div",
            { staticClass: "w-100 block" },
            [
              _c("ProgressBar", {
                attrs: { show: "", percent: _vm.value.progress },
              }),
            ],
            1
          )
        : _vm.isError
        ? _c("i", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: _vm.error,
                expression: "error",
                modifiers: { hover: true },
              },
            ],
            staticClass: "btn py-0 fd-warning text-danger",
          })
        : _c("i", { staticClass: "btn py-0 fd-check text-primary" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }