var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-light h-100" },
    [
      _c("Header", {
        staticClass: "position-absolute",
        attrs: { "logo-only": "" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "container d-flex align-items-center justify-content-center flex-column flex-grow-1 pb-5 text-center",
        },
        [
          _c("h2", { staticClass: "mb-3 font-weight-normal" }, [
            _vm._v(_vm._s(_vm.$t("Thank you for signing up")) + "!"),
          ]),
          _vm._v(" "),
          _c("h1", { staticClass: "mb-5" }, [
            _vm._v(_vm._s(_vm.$t("We are setting up your dashboard"))),
          ]),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: "https://translations.fooddocs.ee/app/fooddocs_setting_up.webp",
              alt: "FoodDocs setting up",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }