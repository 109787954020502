var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.crumbs && _vm.crumbs.length
    ? _c("div", [
        _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
          _c(
            "ol",
            { staticClass: "breadcrumb bg-transparent" },
            _vm._l(_vm.crumbs, function (crumb, index) {
              return _c(
                "li",
                {
                  key: crumb.name + index,
                  staticClass: "breadcrumb-item",
                  class: index === _vm.crumbs.length - 1 ? "active" : null,
                  attrs: {
                    "aria-current":
                      index === _vm.crumbs.length - 1 ? "page" : null,
                  },
                },
                [
                  _c(
                    "RouterLink",
                    { attrs: { to: crumb.routerLink, tag: "a" } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t(crumb.name)) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }