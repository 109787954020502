var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("SideNav"),
      _vm._v(" "),
      _c("PortalTarget", {
        staticClass: "d-none d-md-block",
        attrs: { name: "sidemenu" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column w-100" },
        [
          _c("AppHeader", {
            ref: "app-header",
            class: `app-header ${
              _vm.$store.getters["global/showHeader"] || _vm.hideNav
                ? ""
                : "d-none"
            }`,
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex flex-md-grow-1 app-content-margin-top",
              class: {
                "d-none": _vm.hideContent,
                "extended-header": _vm.showBillingDaysLeftNotification,
              },
            },
            [
              _vm.$store.getters.isCompaniesInited
                ? _c("router-view", { attrs: { name: "base" } })
                : _vm._e(),
              _vm._v(" "),
              _c("PortalTarget", {
                staticClass: "d-md-none",
                attrs: { name: "sidemenu" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }