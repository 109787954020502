var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled
    ? _c("ul", { staticClass: "navbar-nav" }, [
        _c("li", { staticClass: "nav-item dropdown" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                "aria-expanded": "false",
                "aria-haspopup": "true",
                "data-toggle": "dropdown",
                href: "#",
                role: "button",
              },
            },
            [
              _c("img", {
                staticClass: "mr-1 lang-shadow",
                attrs: {
                  src: `https://translations.fooddocs.ee/app/flags/${_vm.currentFlag}.svg`,
                  height: "20",
                  alt: `${_vm.currentFlag}.svg`,
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu",
              class: _vm.position,
              attrs: { "aria-labelledby": "navbarDropdown" },
            },
            _vm._l(_vm.unselectedLanguages, function (lang) {
              return _c(
                "a",
                {
                  key: lang.key,
                  staticClass: "dropdown-item",
                  on: {
                    click: function ($event) {
                      return _vm.setLanguage(lang.key)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      alt: `${lang && lang.flag}.svg`,
                      src: `https://translations.fooddocs.ee/app/flags/${
                        lang && lang.flag
                      }.svg`,
                      height: "15",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-body px-2" }, [
                    _vm._v(_vm._s(lang.text)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }