var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column not-found" },
    [
      _c("AppHeader", {
        class: `${_vm.$store.getters["global/showHeader"] ? "" : "d-none"}`,
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container not-found__block" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-7 mb-5 mb-md-0 d-flex flex-column justify-content-center align-items-start",
            },
            [
              _c("h1", [
                _vm._v(
                  _vm._s(_vm.$t("The page you are looking for doesn’t exist"))
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "You may have mistyped the address or the page may have moved."
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "RouterLink",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", to: { name: "overview" } },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("Go to homepage")) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-5 text-center" }, [
            _c("img", {
              staticClass: "img-fluid",
              attrs: {
                src: "/assets/img/page_not_found.png",
                alt: _vm.$t("Page not found"),
              },
            }),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }