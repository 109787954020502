var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        centered: "",
        "hide-header": "",
        "hide-footer": "",
        size: "lg",
        "modal-class": "welcome-modal",
        "no-close": "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-items-center space-unrelated-out",
        },
        [
          _vm.questionnaire.heading
            ? _c("div", { staticClass: "text-center mt-1" }, [
                _c("h1", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t(_vm.questionnaire.heading)),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-md-3" }, [
            _c("div", { staticClass: "col-12 col-md-6 order-md-1" }, [
              _vm.questionImage
                ? _c("div", [
                    _c("img", {
                      staticClass: "question-image",
                      attrs: { alt: _vm.questionImage, src: _vm.questionImage },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6 order-md-0" }, [
              _c("h3", {
                domProps: { innerHTML: _vm._s(_vm.$t(_vm.questionText)) },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "row d-inline-flex justify-content-center mb-1",
                },
                _vm._l(_vm.options, function (option) {
                  return _c(
                    "div",
                    { key: option.id, staticClass: "col-12" },
                    [
                      _c("BaseRadio", {
                        key: option.id,
                        attrs: {
                          id: `option_${option.id}`,
                          val: option.id,
                          name: "options",
                          label: _vm.$t(option.option),
                          "has-space-between": "",
                        },
                        model: {
                          value: _vm.selectedOption,
                          callback: function ($$v) {
                            _vm.selectedOption = $$v
                          },
                          expression: "selectedOption",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-md-1" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { disabled: !_vm.selectedOption },
                  on: { click: _vm.submitOption },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.isLastQuestion ? _vm.$t("Finish") : _vm.$t("next")
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }