var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        centered: "",
        "hide-footer": "",
        size: "md",
        "modal-class": "refreshModal",
        "hide-header": "",
        "no-close": "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "text-center d-flex flex-column align-items-center space-unrelated-out",
        },
        [
          _c("img", {
            staticClass: "space-related-out",
            attrs: { src: "/img/fd_clean.svg", alt: "Cleaning hand" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "h2 space-related-out" }, [
            _vm._v(
              _vm._s(
                _vm.$t("FoodDocs has probably been updated in the background.")
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "space-related-out-x" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("Please refresh the page.")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "space-related-out-b font-weight-bold d-flex justify-content-center",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-primary space-related-out text-uppercase",
                  attrs: { type: "button" },
                  on: { click: _vm.reloadPage },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("Refresh now")) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }