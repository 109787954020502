var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEditable
    ? _c(
        "div",
        {
          ref: "element",
          staticClass: "base-select multiselect-wrapper form-group mb-0",
          class: {
            "border-0": _vm.borderless,
            "d-none-for-print": !_vm.val,
          },
        },
        [
          _c("TwoRow", {
            staticClass: "h-100",
            attrs: { "ignore-first": !_vm.isLabelSlot, "first-tag": "label" },
            scopedSlots: _vm._u(
              [
                {
                  key: "first",
                  fn: function () {
                    return [_vm._t("label")]
                  },
                  proxy: true,
                },
                {
                  key: "second",
                  fn: function () {
                    return [
                      _c("ValidationProvider", {
                        attrs: {
                          name: _vm.name,
                          rules: _vm.rules,
                          mode: "eager",
                          slim: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (v) {
                                return [
                                  _c("Multiselect", {
                                    class: [
                                      _vm.selectClass,
                                      {
                                        "is-invalid":
                                          (_vm.validation &&
                                            _vm.validation.$error) ||
                                          v.errors.length,
                                      },
                                      { "no-dropdown": _vm.hideDropdown },
                                    ],
                                    attrs: {
                                      placeholder:
                                        _vm.placeholder ||
                                        (_vm.searchable
                                          ? _vm.$t("Search")
                                          : _vm.$t("choose")),
                                      options: _vm.options,
                                      "allow-empty": !_vm.notEmpty,
                                      "show-labels": false,
                                      label: _vm.label,
                                      max: _vm.max,
                                      "custom-label": _vm.customLabel,
                                      "track-by": _vm.trackBy,
                                      searchable: _vm.isSearchable,
                                      "internal-search": _vm.internalSearch,
                                      multiple: _vm.multiple,
                                      taggable: _vm.taggable,
                                      disabled: _vm.disabled,
                                      "tag-position": "bottom",
                                      "tag-placeholder": `${_vm.$t("add")} +`,
                                      "block-keys": ["Delete"],
                                      "open-direction": _vm.isMobile
                                        ? "bottom"
                                        : null,
                                      "group-values": _vm.groupValues,
                                      "group-label": _vm.groupLabel,
                                      "aria-invalid":
                                        v.ariaInput["area-invalid"],
                                      "aria-required":
                                        v.ariaInput["aria-required"],
                                    },
                                    on: {
                                      select: _vm.onSelect,
                                      remove: _vm.onRemove,
                                      "search-change": _vm.onChange,
                                      open: _vm.onFocus,
                                      close: _vm.onBlur,
                                      tag: _vm.onTag,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "noResult",
                                          fn: function () {
                                            return [
                                              _vm._t("noResult", function () {
                                                return [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$t(
                                                          _vm.notFoundMessage
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "noOptions",
                                          fn: function () {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Type to search")
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        _vm.optionType === "paginator"
                                          ? {
                                              key: "singleLabel",
                                              fn: function (paginatorProps) {
                                                return [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        paginatorProps.option
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .$t("Per page")
                                                          .toLowerCase()
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              },
                                            }
                                          : null,
                                        _vm.optionType === "paginator"
                                          ? {
                                              key: "option",
                                              fn: function (
                                                paginatorOptionProps
                                              ) {
                                                return [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        paginatorOptionProps.option
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .$t("Per page")
                                                          .toLowerCase()
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              },
                                            }
                                          : null,
                                        {
                                          key: "caret",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "multiselect__custom-select d-none-for-print",
                                                },
                                                [
                                                  !_vm.hideDropdown
                                                    ? _c("i", {
                                                        staticClass:
                                                          "fd-chevron-down",
                                                        class: _vm.arrowClass,
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "afterList",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "w-100 d-flex" },
                                                [_vm._t("afterList")],
                                                2
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.val,
                                      callback: function ($$v) {
                                        _vm.val = $$v
                                      },
                                      expression: "val",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.isErrorSlot &&
                                  _vm.validation &&
                                  _vm.validation.$error
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "invalid-feedback d-block",
                                        },
                                        [
                                          _vm._t("error", null, {
                                            validation: _vm.validation,
                                          }),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  v.errors.length
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "invalid-feedback d-block",
                                          attrs: { "data-cy": "error-message" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(v.errors[0]) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    : _c("span", [
        _vm.customToString
          ? _c("span", [_vm._v(_vm._s(_vm.customToString))])
          : _vm.multiple
          ? _c(
              "span",
              _vm._l(_vm.value, function (v, i) {
                return _c("span", { key: i }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(v.text) +
                      _vm._s(i !== _vm.value.length - 1 ? ", " : "") +
                      "\n        "
                  ),
                ])
              }),
              0
            )
          : _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.stringify.text || _vm.stringify || "-") +
                  "\n    "
              ),
            ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }