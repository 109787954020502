var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseSelect",
    {
      class: { "unit-field": !_vm.disabled },
      attrs: {
        id: `ingredient-production-unit_${_vm.id}`,
        validation: _vm.validation,
        "custom-label": (unit) =>
          _vm.$t(_vm.shortHand ? unit.short_name : unit.name),
        options: _vm.productionUnits,
        placeholder: _vm.$t("unit"),
        label: "name",
        "is-editable": !_vm.disabled,
        value: _vm.selectedValue || "",
      },
      on: { input: _vm.update },
      model: {
        value: _vm.selectedValue,
        callback: function ($$v) {
          _vm.selectedValue = $$v
        },
        expression: "selectedValue",
      },
    },
    [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.selectedValue.short_name
              ? _vm.$t(_vm.selectedValue.short_name)
              : "-"
          ) +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }