var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "logo-fade" } }, [
        !_vm.small
          ? _c("img", {
              class: _vm.responsive ? "d-none d-lg-inline-block" : "",
              attrs: {
                src: "/img/fd_logo.svg",
                width: _vm.width,
                height: _vm.height,
                alt: "FoodDocs logo",
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.responsive || _vm.small
        ? _c("img", {
            class: _vm.responsive ? "d-inline-block d-lg-none" : "",
            attrs: {
              height: (_vm.height !== 40 && _vm.height) || _vm.responsiveHeight,
              width: _vm.responsiveWidth,
              src: "/img/fd_avatar.svg",
              alt: "FoodDocs logo",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }