var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar row d-flex justify-content-between align-items-center w-100",
    },
    [
      _c(
        "div",
        { staticClass: "register-header" },
        [
          _c(
            "span",
            {
              staticClass: "cursor-pointer",
              on: { click: _vm.redirectToLandingPage },
            },
            [_c("Logo", { attrs: { responsive: "" } })],
            1
          ),
          _vm._v(" "),
          !_vm.logoOnly
            ? _c("LanguageSwitcher", {
                staticClass: "d-inline-block ml-3",
                attrs: { position: "dropdown-menu position-absolute" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isCreateNew && _vm.redirectTo && !_vm.isCreatePlace
        ? _c(
            "RouterLink",
            {
              attrs: { to: { name: _vm.redirectTo }, "data-cy": "close" },
              on: { click: _vm.restoreCompanyAndPlace },
            },
            [_c("i", { staticClass: "fd-close" })]
          )
        : _vm.hasProfile
        ? _c("div", { staticClass: "btn-group" }, [
            _c(
              "a",
              {
                staticClass:
                  "d-flex align-items-center cursor-pointer user-logo",
                attrs: {
                  id: "profile",
                  "aria-expanded": "false",
                  "aria-haspopup": "true",
                  "data-toggle": "dropdown",
                },
              },
              [
                _c("i", { staticClass: "fd-user-circle mr-1" }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "text-dark mr-1",
                    attrs: { id: "profile-name" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getUserDisplayName) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "fd-chevron-down font-size-sm" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dropdown-menu dropdown-menu-right",
                attrs: { id: "nav-menu" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "dropdown-item d-flex justify-content-between align-items-center",
                    attrs: { type: "button" },
                    on: { click: _vm.logout },
                  },
                  [
                    _c("div", { staticClass: "d-inline-block" }, [
                      _vm._v(_vm._s(_vm.$t("log_out"))),
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "fd-sign-out pl-2" }),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }