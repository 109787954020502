import { DateValue } from '@common/types';

import stylesheets from '@components/BaseDatePicker/fd-base-date-picker/style';

class FdDatePicker extends HTMLElement {
    private inputElement!: HTMLInputElement;
    private datePickerElement!: HTMLElement;
    private startValue: String = '';
    private _dateValue: DateValue | null = null;
    static stylesheet: CSSStyleSheet = stylesheets;

    constructor() {
        super();
        const font = document.createElement('link');
        font.href = 'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap';
        font.rel = 'stylesheet';
        document.head.appendChild(font);
        this.attachShadow({ mode: 'open' });
    }

    private render(): void {
        this.shadowRoot!.adoptedStyleSheets = [FdDatePicker.stylesheet];
        this.shadowRoot!.innerHTML = `
        <div class="base-datepicker">
            <slot id="label" name="picker-label"></slot>
            <div class="date-input">
              <input
              id="date"
              type="text"
              autocomplete="off"
              placeholder="${DateValue.getCompanyDateFormat()}"
              value="${this._dateValue?.dateFormat || ''}"
              >
              </input>
              <slot class="date-picker-label-margin" name="icon-label"></slot>
            </div>
                <div id="grid"></div>
        </div>
    `;
        this.initializeElements();
    }

    connectedCallback() {
        this.render();
        setTimeout(() => {
            this.initializeElements();
            this.addEventListeners();
            this.updateInputValue();
        }, 0);
    }
    static get observedAttributes() {
        return ['value'];
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'value') {
            this.startValue = newValue;
            const valueIsSet = newValue != '' && newValue != null;
            this._dateValue = valueIsSet ? DateValue.fromFormattedDate(newValue) : null;
            this.updateInputValue();
        }
    }

    disconnectedCallback() {
        // Clean up the global event listener when the element is removed from the DOM
        document.removeEventListener('datepicker-opened', this.handleOtherDatePickerOpened.bind(this) as EventListener);
        document.removeEventListener('click', this.handleDocumentClick.bind(this));
    }

    private notifyDatePickerOpened(): void {
        const openEvent = new CustomEvent('datepicker-opened', {
            detail: { openedPicker: this },
            bubbles: true,
            composed: true, // Allows the event to bubble across the shadow DOM boundary
        });
        this.dispatchEvent(openEvent);
    }

    private handleOtherDatePickerOpened(event: CustomEvent): void {
        if (event.detail.openedPicker !== this) {
            this.closeCalendar();
        }
    }

    private initializeElements(): void {
        this.inputElement = this.shadowRoot!.querySelector('#date') as HTMLInputElement;
        this.datePickerElement = this.shadowRoot!.querySelector('#grid') as HTMLElement;
        this.datePickerElement.style.display = 'none'; //   the calendar grid is initially hidden
    }

    private addEventListeners(): void {
        this.addEventListener('focus', () => this.inputElement.focus());

        this.inputElement.addEventListener('focus', () => this.openCalendar());

        this.inputElement.addEventListener('input', (event) => this.handleInputChange(event));

        this.inputElement.addEventListener('keyup', this.handleKeyPress.bind(this) as EventListener);

        document.addEventListener('click', this.handleDocumentClick.bind(this));
        document.addEventListener('datepicker-opened', this.handleOtherDatePickerOpened.bind(this) as EventListener);

        this.shadowRoot!.addEventListener('click', (event) => {
            // Event delegation for month navigation hack
            const target = event.target as Element;
            if (target.id === 'prev-month') {
                this.changeMonth(-1);
            } else if (target.id === 'next-month') {
                this.changeMonth(1);
            }
        });
    }

    private handleInputChange(event: Event): void {
        event.stopPropagation();
        const input = event.target as HTMLInputElement;
        const newDate = DateValue.fromFormattedDate(input.value);
        const newIsInvalid =
            !newDate.momentValue.isValid() ||
            (newDate.dateFormat.length > 0 && input.value.length > 0 && newDate.dateFormat !== input.value);
        const noChange = this.inputElement.value === this._dateValue?.dateFormat;

        if (newIsInvalid) {
            this.inputElement.style.color = 'red';
            return;
        }

        if (noChange) {
            return;
        }

        this.inputElement.style.color = '';
        this._dateValue = newDate;
        this.dispatchInputEvent();
    }

    private handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.closeCalendar();
        }
    }

    private handleDocumentClick(event: MouseEvent): void {
        // Ensure that the click outside the element closes the calendar
        const path = event.composedPath();
        if (!path.includes(this)) {
            this.closeCalendar();
        }
    }

    private handleDayClick(day: DateValue): void {
        this._dateValue = day;
        this.closeCalendar();
    }

    private openCalendar(): void {
        this.notifyDatePickerOpened(); // Notify other components that this date picker is opening

        this.populateCalendar();
        this.datePickerElement.style.display = 'block';

        if (this.datePickerElement.getBoundingClientRect().right > window.innerWidth) {
            this.datePickerElement.style.inset = '50px 0px auto auto';
        }
    }

    private closeCalendar(): void {
        this.dispatchInputEvent();
        this.blur();
        this.datePickerElement.style.display = 'none';
    }

    private dispatchInputEvent(): void {
        if (this._dateValue?.dateFormat === this.startValue) {
            this.updateInputValue();
            return;
        }
        this.dispatchEvent(new CustomEvent('input', { detail: this._dateValue }));
    }

    private updateInputValue(): void {
        if (this.inputElement) {
            this.inputElement.value = this._dateValue?.dateFormat || '';
        }
    }

    private changeMonth(change: number): void {
        this._dateValue = this.dateValue;
        this._dateValue.momentValue.add(change, 'months');
        this.populateCalendar();
        this.inputElement.focus(); // Because the bloody click takes focus from the Element...
    }

    private populateCalendar(): void {
        const calendarContainer = this.shadowRoot!.querySelector('#grid') as HTMLElement;
        const activeMonth = parseInt(this.dateValue.format('MM'));
        const activeYear = this.dateValue.format('YYYY');
        const monthTranslation = DateValue.monthsTranslationKeys()[activeMonth - 1];
        calendarContainer.innerHTML = `
        <div class="controls">
          <div class="month">${monthTranslation + ' ' + activeYear}</div>
          <div class="buttons">
            <button id="prev-month" class="btn prev-month">
              <slot name="prev-button-icon"></slot>
            </button>

            <button id="next-month" class="btn next-month">
              <slot name="next-button-icon"></slot>
            </button>
          </div>
        </div>

        <div class="days-grid"></div>
`;
        this.generateCalendarDays();
    }
    generateCalendarDays(): void {
        const daysGrid = this.datePickerElement.querySelector('.days-grid')!;
        daysGrid.innerHTML = '';

        const translatedDays = DateValue.daysTranslationKeysLetters();
        const daysRow = document.createElement('div');
        daysRow.className = 'row';
        translatedDays.forEach((day) => {
            const dayElement = document.createElement('div');
            dayElement.className = 'day-name';
            dayElement.textContent = day;
            daysRow.appendChild(dayElement);
        });
        daysGrid.appendChild(daysRow);

        const startOfMonth = this.dateValue.startOf('month');
        const endOfMonth = this.dateValue.endOf('month');
        const startOfCalendar = startOfMonth.startOf('isoWeek');
        const endOfCalendar = endOfMonth.endOf('isoWeek');

        let row = document.createElement('div');
        row.className = 'row';

        for (
            let day = startOfCalendar;
            day.isBefore(endOfCalendar);
            day.isAfterDate(new DateValue(day.momentValue.add('days', 1)))
        ) {
            const dayElement = document.createElement('button');
            dayElement.className =
                day.isBeforeDate(startOfMonth) || day.isAfterDate(endOfMonth) ? 'day other-month' : 'day';
            dayElement.setAttribute('data-date', day.format('YYYY-MM-DD'));
            dayElement.textContent = day.format('DD');

            if (day.momentValue.month() !== startOfMonth.momentValue.month()) {
                dayElement.classList.add('not-current-month');
            } else if (day.diffInDays(this.dateValue) === 0) {
                dayElement.classList.add('selected');
            }

            dayElement.addEventListener('click', () => {
                this.handleDayClick(DateValue.fromRequestDate(dayElement.getAttribute('data-date')!));
            });
            row.appendChild(dayElement);
            if (day.momentValue.isoWeekday() === 7) {
                daysGrid.appendChild(row);
                row = document.createElement('div');
                row.className = 'row';
            }
        }
        if (row.hasChildNodes()) {
            daysGrid.appendChild(row);
        }
    }

    get dateValue(): DateValue {
        return this._dateValue || new DateValue();
    }
}

export default FdDatePicker;
