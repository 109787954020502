var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AccessLevel", {
    attrs: { "disable-for": ["company-readonly"] },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ denied }) {
            return [
              _c(
                "BaseForm",
                {
                  attrs: { "data-cy": "place-form" },
                  on: { submit: _vm.onSubmit },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "name" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Food handling place name")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "name",
                              "is-editable": !denied && _vm.isEditMode,
                              "data-cy": "name-field",
                              rules: "required",
                            },
                            model: {
                              value: _vm.data.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "name", $$v)
                              },
                              expression: "data.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "address" },
                        },
                        [_vm._v(_vm._s(_vm.$t("address")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "address",
                              "is-editable": !denied && _vm.isEditMode,
                              "data-cy": "address-field",
                              rules: "required",
                            },
                            model: {
                              value: _vm.data.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "address", $$v)
                              },
                              expression: "data.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "country" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Country")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseSelect", {
                            attrs: {
                              id: "country",
                              rules: "required",
                              "is-editable": !denied && _vm.isEditMode,
                              "data-cy": "country-field",
                              options: _vm.countries,
                              "custom-label": (item) => _vm.$t(item.name),
                              placeholder: _vm.$t("Choose country"),
                              searchable: "",
                              "not-empty": "",
                            },
                            model: {
                              value: _vm.data.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "country", $$v)
                              },
                              expression: "data.country",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasStates
                    ? _c(
                        "div",
                        { staticClass: "form-group row align-items-center" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label col-md-5",
                              attrs: { for: "state" },
                            },
                            [_vm._v(_vm._s(_vm.$t("State")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-7" },
                            [
                              _c("BaseSelect", {
                                attrs: {
                                  id: "state",
                                  rules: "required",
                                  "is-editable": !denied && _vm.isEditMode,
                                  "data-cy": "state-field",
                                  options: _vm.countryStates,
                                  "custom-label": (item) => _vm.$t(item.name),
                                  placeholder: _vm.$t("Choose state"),
                                  searchable: "",
                                  "not-empty": "",
                                },
                                model: {
                                  value: _vm.data.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "state", $$v)
                                  },
                                  expression: "data.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "phone" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Phone")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "phone",
                              "is-editable": !denied && _vm.isEditMode,
                              rules: "required",
                              "data-cy": "phone-field",
                            },
                            model: {
                              value: _vm.data.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "phone", $$v)
                              },
                              expression: "data.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "email" },
                        },
                        [_vm._v(_vm._s(_vm.$t("email")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "email",
                              "is-editable": !denied && _vm.isEditMode,
                              "data-cy": "email-field",
                              rules: "required|email",
                            },
                            model: {
                              value: _vm.data.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "email", $$v)
                              },
                              expression: "data.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "col-form-label col-md-5 d-flex align-items-center",
                          attrs: { for: "notification-email" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("notification_email")) +
                              "\n                "
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "fd-info cursor-pointer ml-1",
                            attrs: {
                              title: _vm.$t(
                                "Add Multiple emails by using comma(,) as separation character"
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "notification-email",
                              "is-editable": !denied && _vm.isEditMode,
                              "data-cy": "notification-email-field",
                              rules: "email",
                            },
                            model: {
                              value: _vm.data.notificationEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "notificationEmail", $$v)
                              },
                              expression: "data.notificationEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "representative-person" },
                        },
                        [_vm._v(_vm._s(_vm.$t("representative")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "representative-person",
                              "is-editable": !denied && _vm.isEditMode,
                              rules: "required",
                              "data-cy": "representative-person-field",
                            },
                            model: {
                              value: _vm.data.representativePerson,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "representativePerson", $$v)
                              },
                              expression: "data.representativePerson",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "main-activity-category" },
                        },
                        [_vm._v(_vm._s(_vm.$t("business_activity")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseSelect", {
                            attrs: {
                              id: "main-activity-category",
                              "is-editable": !denied && _vm.isEditMode,
                              rules: "required",
                              options: _vm.businessActivities,
                              "custom-label": (item) => _vm.$t(item.name),
                              "not-empty": "",
                            },
                            model: {
                              value: _vm.businessActivity,
                              callback: function ($$v) {
                                _vm.businessActivity = $$v
                              },
                              expression: "businessActivity",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "food-handling-place" },
                        },
                        [_vm._v(_vm._s(_vm.$t("food_handling_place")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseSelect", {
                            attrs: {
                              id: "food-handling-place",
                              "is-editable": !denied && _vm.isEditMode,
                              rules: "required",
                              "data-cy": "location-type-field",
                              options: _vm.locationTypes,
                              "custom-label": (item) => _vm.$t(item.name),
                            },
                            model: {
                              value: _vm.data.locationType,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "locationType", $$v)
                              },
                              expression: "data.locationType",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "number-of-employees" },
                        },
                        [_vm._v(_vm._s(_vm.$t("number_of_employees")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseSelect", {
                            attrs: {
                              id: "number-of-employees",
                              "is-editable": !denied && _vm.isEditMode,
                              rules: "required",
                              "data-cy": "number-of-employees-field",
                              options: _vm.employeeNumbersOptions,
                            },
                            model: {
                              value: _vm.data.numberOfEmployees,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "numberOfEmployees", $$v)
                              },
                              expression: "data.numberOfEmployees",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "preferred-language" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Preferred language")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("BaseSelect", {
                            attrs: {
                              id: "preferred-language",
                              "is-editable": !denied && _vm.isEditMode,
                              options: _vm.availableLanguages,
                              "custom-label": (item) => item.name,
                              placeholder: _vm.$t("Choose preferred language"),
                              "not-empty": "",
                            },
                            model: {
                              value: _vm.data.preferredLanguage,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "preferredLanguage", $$v)
                              },
                              expression: "data.preferredLanguage",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label col-md-5",
                          attrs: { for: "preferred-language" },
                        },
                        [_vm._v(_vm._s(_vm.$t("Time zone")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("TimezoneSelect", {
                            attrs: {
                              "is-edit-mode": !denied && _vm.isEditMode,
                            },
                            model: {
                              value: _vm.data.timezone,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "timezone", $$v)
                              },
                              expression: "data.timezone",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("default"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      !denied
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-7 offset-md-5 space-related-out-b",
                            },
                            [
                              _vm.isEditMode
                                ? _c("span", { staticClass: "d-flex" }, [
                                    !_vm.hideCancel
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary w-50 space-related-out-r",
                                            on: { click: _vm.stopEditMode },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.$t("Cancel")) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: !_vm.hideCancel
                                          ? "space-related-out-l w-50"
                                          : "w-100",
                                        attrs: {
                                          id: "company-creation-save",
                                          type: "submit",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.$t("save")) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ])
                                : !_vm.isEditMode
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-block",
                                      attrs: {
                                        id: "company-creation-edit",
                                        type: "button",
                                      },
                                      on: { click: _vm.startEditMode },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("edit")) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }