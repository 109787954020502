var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.layout === "horizontal"
    ? _c("div", [
        _c("div", { staticClass: "form-group row justify-content-center" }, [
          _vm.title
            ? _c(
                "label",
                {
                  staticClass:
                    "col-md-2 col-form-label text-md-right align-self-md-center",
                  attrs: { id: _vm.id, for: _vm.id },
                },
                [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "div",
                { staticClass: "text-right" },
                _vm._l(_vm.langs, function (item) {
                  return _c(
                    "button",
                    {
                      key: item,
                      staticClass: "btn btn-sm text-uppercase",
                      class: {
                        "btn-primary": item === _vm.lang,
                        "border-danger":
                          !_vm.data[_vm.lang] && item !== _vm.lang,
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.setLang(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("QuillEditor", {
                class: _vm.inputClass,
                attrs: { id: _vm.id },
                on: { input: _vm.update },
                model: {
                  value: _vm.data[_vm.lang],
                  callback: function ($$v) {
                    _vm.$set(_vm.data, _vm.lang, $$v)
                  },
                  expression: "data[lang]",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _c(
        "div",
        [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("div", [
              _vm.title
                ? _c("label", { attrs: { for: _vm.id } }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _c("div", { staticClass: "form-group mb-0" }, [
                    _c(
                      "label",
                      { attrs: { for: _vm.id } },
                      [_vm._t("label")],
                      2
                    ),
                  ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-group" },
              _vm._l(_vm.langs, function (item) {
                return _c(
                  "button",
                  {
                    key: item,
                    staticClass: "btn btn-sm text-uppercase",
                    class: {
                      "btn-primary": item === _vm.lang,
                      "border-danger": !_vm.data[_vm.lang] && item !== _vm.lang,
                    },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setLang(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(item) + "\n            "
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("QuillEditor", {
            class: _vm.inputClass,
            attrs: { id: _vm.id, options: _vm.editorOption },
            on: {
              input: _vm.update,
              blur: function ($event) {
                _vm.validation && _vm.validation.$touch()
              },
            },
            model: {
              value: _vm.data[_vm.lang],
              callback: function ($$v) {
                _vm.$set(_vm.data, _vm.lang, $$v)
              },
              expression: "data[lang]",
            },
          }),
          _vm._v(" "),
          _vm.validation && _vm.validation.$error
            ? _c(
                "span",
                { staticClass: "invalid-feedback" },
                [_vm._t("error", null, { validation: _vm.validation })],
                2
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }