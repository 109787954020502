var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading && !_vm.$route.query.fromSetup
    ? _c(
        "div",
        {
          staticClass:
            "bg-gray-200 border-bottom border-top pl-3 pt-1 space-unrelated-in d-flex align-items-center justify-content-center",
        },
        [
          _c("span", { staticClass: "space-related-in-x" }, [
            _vm._v(_vm._s(_vm.$t("Your HACCP is being initialized."))),
          ]),
          _c("span", { staticClass: "loader" }),
        ]
      )
    : _vm.generalDataOnceFilled || !_vm.$route.query.fromSetup
    ? _c(
        "div",
        { staticClass: "bg-gray-200 border-bottom border-top pl-3 pt-1" },
        [
          _vm.showConfirmButton || !_vm.planConfirmed
            ? _c("div", [
                _vm.planFilled
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "row plan-header-row bg-green-light space-related-in d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mr-2 font-size-xs mt-1 d-flex align-items-center",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "You have made changes to your HACCP plan."
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mt-1",
                            attrs: {
                              id: "confirm-plan",
                              type: "button",
                              disabled: _vm.isLoading,
                            },
                            on: { click: _vm.confirmPlan },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("Confirm changes")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "row plan-header-row bg-gray-200 space-unrelated-in d-flex align-items-center justify-content-center",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Not all sections of your HACCP are filled. Incomplete sections in the menu are marked with"
                              )
                            ) +
                            "\n            "
                        ),
                        _c("i", { staticClass: "fd-circle text-red ml-2" }),
                        _vm._v(
                          ".\n            " +
                            _vm._s(
                              _vm.$t(
                                "Fill the required parts completely in order to confirm, download and share your plan."
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
              ])
            : _vm.planConfirmed || !_vm.confirmedBy
            ? _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex align-items-center justify-content-between space-related-in",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column justify-content-center",
                    },
                    [
                      _c("div", { staticClass: "overflow-ellipsis" }, [
                        _vm._v(
                          _vm._s(_vm.$t("HACCP")) +
                            " - " +
                            _vm._s(_vm.selectedPlace.name)
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.planConfirmed
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex flex-column align-items-end font-size-xs",
                                },
                                [
                                  _c("span", { staticClass: "text-gray-500" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.$t("Confirmed")) +
                                        " " +
                                        _vm._s(
                                          _vm.$filters.formatDate(
                                            _vm.confirmedData.confirmed_at
                                          )
                                        ) +
                                        "\n                        " +
                                        _vm._s(_vm.confirmedBy)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end flex-wrap font-size-xs",
                    },
                    [
                      _c(
                        "RouterLink",
                        {
                          staticClass:
                            "btn text-gray-700 hover-green font-size-xs",
                          attrs: { to: { name: "plan.hazards-summary-list" } },
                        },
                        [
                          _c("i", { staticClass: "fd-haccp mr-2" }),
                          _vm._v(_vm._s(_vm.$t("Summary")) + "\n            "),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$route.name !== "plan.setup" && !_vm.isSharedAccess
                        ? _c(
                            "RouterLink",
                            {
                              staticClass:
                                "btn text-gray-700 hover-green font-size-xs",
                              attrs: { to: { name: "plan.setup" } },
                            },
                            [
                              _c("i", { staticClass: "fd-cog mr-2" }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("Manage")) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showConfirmButton
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn text-gray-700 hover-green font-size-xs",
                              attrs: {
                                id: "download-plan",
                                type: "button",
                                disabled: _vm.isLoading,
                              },
                              on: { click: _vm.downloadFspPdf },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fd-document-download mr-2 text-gray-700",
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("download")) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showConfirmButton
                        ? _c(
                            "RouterLink",
                            {
                              staticClass:
                                "btn text-gray-700 hover-green font-size-xs d-flex align-items-center",
                              attrs: {
                                id: "share",
                                type: "button",
                                disabled: _vm.isLoading,
                                to: { name: "share-accesses-new" },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fd-envelope mr-2 hover-green",
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("Invite to read")) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("BaseModal", {
            ref: "renewableHaccpOfferModal",
            attrs: {
              centered: "",
              "hide-header": "",
              "hide-footer": "",
              size: "md",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ close }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center space-related-out-t" },
                      [
                        _c("img", {
                          staticClass: "space-related-out-b",
                          attrs: {
                            src: "/assets/img/illu_celebrate.svg",
                            alt: "Woman with tablet",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "space-unrelated-out-x" }, [
                          _c(
                            "div",
                            { staticClass: "heading space-related-out-t" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "Thank you for purchasing the HACCP plan!"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "space-related-out-x space-related-out-t",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "Would you like to access your HACCP plan after 30 days"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("Subscribe for digital HACCP"))
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-primary space-related-out",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "billing.choose-payment",
                                      params: { payFor: _vm.fastHACCP },
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("continue")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "cursor-pointer",
                              on: { click: close },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "No thank you, I don't want to store my HACCP"
                                  )
                                ) + "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("BaseModal", {
            ref: "fsp-time-to-join-us",
            attrs: {
              id: "fsp-time-to-join-us",
              "ok-title": _vm.$t("download"),
              "cancel-title": _vm.$t("Cancel"),
              title: _vm.$t("module_food_safety_plan"),
              centered: "",
              "hide-header": "",
              "hide-footer": "",
              size: "md",
              "modal-class": "timeToJoinUs",
            },
            on: { close: _vm.closeTimeToJoinUsModal },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ close }) {
                  return [
                    _c("img", {
                      staticClass: "modal-image",
                      attrs: {
                        alt: _vm.$t("Time to join us"),
                        src: "/assets/img/illu_celebrate.svg",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center mt-1" }, [
                      _c("h1", [_vm._v(_vm._s(_vm.$t("Time to join us")))]),
                      _vm._v(" "),
                      _c("h2", [_vm._v(_vm._s(_vm.timeToJoinUsMessage))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center mt-5 d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary mx-1",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.closeTimeToJoinUsModal(close)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("Cancel")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "RouterLink",
                          {
                            staticClass: "btn btn-primary mx-1",
                            attrs: {
                              type: "button",
                              to: {
                                name: "billing",
                                params: { fromPlan: true },
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("continue")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("CustomizableViewLoader", {
            ref: "planDownloadLoader",
            attrs: {
              heading: _vm.getLoaderHeading(),
              message: _vm.getLoaderMessage(),
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }