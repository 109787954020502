var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex",
      class: { "justify-content-center": _vm.centered },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center align-items-center rounded-circle bg-danger-light p-1 hw-24px align-self-center",
      },
      [_c("i", { staticClass: "fd-close text-danger" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }