<template>
    <AccessLevel
        v-slot="{ denied }"
        :disable-for="['company-readonly']"
    >
        <BaseForm @submit="onSubmit">
            <div class="form-group row align-items-center">
                <label
                    class="col-md-5 col-form-label"
                    for="country"
                >
                    {{ $t('Country') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="country"
                        v-model="data.country"
                        :options="countries"
                        rules="required"
                        :is-editable="!denied && isEditMode"
                        :custom-label="(item) => item.name"
                        :placeholder="$t('Choose country')"
                        searchable
                        not-empty
                    >
                        {{ data.country ? $t(data.country.name) : '-' }}
                    </BaseSelect>
                </div>
            </div>

            <div
                v-if="hasStates"
                class="form-group row align-items-center"
            >
                <label
                    class="col-md-5 col-form-label"
                    for="states"
                >
                    {{ $t('States') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="state"
                        v-model="data.state"
                        :options="countryStates"
                        rules="required"
                        :is-editable="!denied && isEditMode"
                        :custom-label="(item) => item.name"
                        :placeholder="$t('Choose state')"
                        searchable
                        not-empty
                    >
                        {{ data.state ? $t(data.state.name) : '-' }}
                    </BaseSelect>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    for="company_name"
                    class="col-md-5 col-form-label"
                >
                    {{ $t('Company name') }}
                </label>
                <div class="col-md-7">
                    <BaseInput
                        id="company_name"
                        v-model="data.name"
                        :is-editable="!denied && isEditMode"
                        rules="required"
                    >
                        {{ data.name ? data.name : '-' }}
                    </BaseInput>
                </div>
            </div>

            <div
                v-if="companyIsNotUsa"
                class="form-group row align-items-center"
            >
                <label
                    for="registration_code"
                    class="col-md-5 col-form-label"
                >
                    {{ $t('registry_code') }}
                </label>
                <div class="col-md-7">
                    <BaseInput
                        id="registration_code"
                        v-model="data.regCode"
                        :is-editable="!denied && isEditMode"
                        :rules="regCodeRequired"
                    >
                        {{ data.regCode ? data.regCode : '-' }}
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    for="vat_code"
                    class="col-md-5 col-form-label"
                >
                    {{ $t('vat_code') }}
                </label>
                <div class="col-md-7">
                    <BaseInput
                        id="vat_code"
                        v-model="data.vatCode"
                        :rules="isEuCountries ? { regex: vatCodeRegex } : ''"
                        :placeholder="vatPlaceholder"
                        uppercase
                        :is-editable="!denied && isEditMode"
                    >
                        {{ data.vatCode ? data.vatCode : '-' }}
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    for="address"
                    class="col-md-5 col-form-label"
                >
                    {{ $t('address') }}
                </label>
                <div class="col-md-7">
                    <BaseInput
                        id="address"
                        v-model="data.address"
                        rules="required"
                        :is-editable="!denied && isEditMode"
                    >
                        {{ data.address ? data.address : '-' }}
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    for="address"
                    class="col-md-5 col-form-label"
                >
                    {{ $t('email_address') }}
                </label>
                <div class="col-md-7">
                    <BaseInput
                        id="email"
                        v-model="data.email"
                        rules="required|email"
                        :is-editable="!denied && isEditMode"
                    >
                        {{ data.email ? data.email : '-' }}
                    </BaseInput>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-md-5 col-form-label"
                    for="preferred-language"
                >
                    {{ $t('Preferred language') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="preferred-language"
                        v-model="data.preferredLanguage"
                        :options="availableLanguages"
                        :custom-label="(item) => item.name"
                        :placeholder="$t('Choose preferred language')"
                        not-empty
                        :is-editable="!denied && isEditMode"
                    >
                        {{ data.preferredLanguage ? $t(data.preferredLanguage.name) : '-' }}
                    </BaseSelect>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-md-5 col-form-label"
                    for="volume-system"
                >
                    {{ $t('Volume system') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="volume-system"
                        v-model="data.volumeSystem"
                        :is-editable="!denied && isEditMode"
                        :options="measurementSystems"
                        :custom-label="(item) => $t(item.name)"
                        :placeholder="$t('Choose volume system')"
                        not-empty
                    >
                        {{ data.volumeSystem ? $t(data.volumeSystem.name) : '-' }}
                    </BaseSelect>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label
                    class="col-md-5 col-form-label"
                    for="weight-system"
                >
                    {{ $t('Weight system') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="weight-system"
                        v-model="data.weightSystem"
                        :options="measurementSystems"
                        :custom-label="(item) => $t(item.name)"
                        :placeholder="$t('Choose weight system')"
                        :is-editable="!denied && isEditMode"
                        not-empty
                    >
                        {{ data.weightSystem ? $t(data.weightSystem.name) : '-' }}
                    </BaseSelect>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label
                    class="col-md-5 col-form-label"
                    for="temp-system"
                >
                    {{ $t('Temperature system') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="temp-system"
                        v-model="data.tempScale"
                        :options="tempScales"
                        :custom-label="(item) => $t(item.name)"
                        :placeholder="$t('Choose temperature system')"
                        :is-editable="!denied && isEditMode"
                        not-empty
                    >
                        {{ data.tempScale ? $t(data.tempScale.name) : '-' }}
                    </BaseSelect>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-lg-5 col-form-label"
                    for="entry-log"
                >
                    {{ $t('Entry log') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="entry-log"
                        v-model="entryLog"
                        class="w-100"
                        rules="required"
                        :options="entryLogOptions"
                        :custom-label="(i) => $t(i)"
                        not-empty
                        :is-editable="!denied && isEditMode"
                    >
                        {{ entryLog ? $t(entryLog) : '-' }}
                    </BaseSelect>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-lg-5 col-form-label d-flex align-items-center"
                    for="temp-prefill"
                >
                    {{ $t('Pre-filling solution') }}
                    <i
                        v-b-tooltip.hover
                        class="fd-info font-size-sm cursor-pointer ml-1"
                        :title="$t('This requires you to confirm the numbers or change them')"
                    ></i>
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="temp-prefill"
                        v-model="tempPrefill"
                        class="w-100"
                        rules="required"
                        :options="temperaturePredictionsOptions"
                        :custom-label="(i) => $t(i)"
                        :is-editable="!denied && isEditMode"
                        for="entry-log"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label
                    class="col-lg-5 col-form-label"
                    for="entry-log"
                >
                    {{ $t('Date format') }}
                </label>
                <div class="col-md-7">
                    <BaseSelect
                        id="entry-log"
                        v-model="data.dateFormat"
                        class="w-100"
                        rules="required"
                        :options="dateFormats"
                        :custom-label="(format) => getDateInFormat(format)"
                        not-empty
                        :is-editable="!denied && isEditMode"
                        value-as="id"
                        :custom-to-string="currentDateFormat"
                    />
                </div>
            </div>

            <div class="form-group row align-items-center">
                <div
                    v-if="!denied"
                    class="col-sm-7 offset-md-5 space-related-out-y"
                >
                    <span
                        v-if="isEditMode"
                        class="d-flex"
                    >
                        <button
                            v-if="!hideCancel"
                            class="btn btn-secondary w-50 space-related-out-r"
                            @click="stopEditMode"
                        >
                            {{ $t('Cancel') }}
                        </button>
                        <button
                            id="company-creation-save"
                            type="submit"
                            class="btn btn-primary"
                            :class="!hideCancel ? 'space-related-out-l w-50' : 'w-100'"
                        >
                            {{ $t('save') }}
                        </button>
                    </span>
                    <button
                        v-else-if="isOwner && !isEditMode"
                        id="company-creation-edit"
                        type="button"
                        class="btn btn-primary btn-block"
                        @click="startEditMode"
                    >
                        {{ $t('edit') }}
                    </button>
                </div>
            </div>
        </BaseForm>
    </AccessLevel>
</template>

<script>
import { mapGetters } from 'vuex';

import { DateValue } from '@common/types';

import { FETCH_COUNTRIES, FETCH_CURRENCIES, FETCH_LANGUAGES } from '@store/modules/common/constants';

import { COUNTRIES } from '@common/constants';
import { euCountries, vatCodeRegex } from '@common/validators';

import AccessLevel from '@components/AccessLevel';
import BaseForm from '@components/BaseForm';

const EST_COUNTRY_ID = 63;

export default {
    name: 'CompanyForm',

    components: {
        AccessLevel,
        BaseForm,
    },

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        isEditMode: {
            type: Boolean,
            default: false,
        },
        hideCancel: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['submit'],

    data() {
        return {
            data: {
                name: null,
                address: null,
                country: null,
                state: null,
                phone: null,
                email: null,
                regCode: null,
                vatCode: null,
                preferredLanguage: null,
                volumeSystem: null,
                weightSystem: null,
                tempScale: null,
                entryCreatedAtHidden: null,
                tempPredictionsEnabled: null,
                currency: null,
                dateFormat: null,
            },
        };
    },

    computed: {
        ...mapGetters(['countries', 'selectedCompany', 'isOwner', 'availableLanguages', 'currencies', 'countryStates']),
        ...mapGetters({
            measurementSystems: 'global/measurementSystems',
            tempScales: 'global/tempScales',
            dateFormats: 'classifiers/dateFormats',
        }),
        // FD-6518 hide company reg code for USA
        currentDateFormat() {
            return new DateValue().dateFormat;
        },
        companyIsNotUsa() {
            return this.data?.country?.id !== COUNTRIES.UNITED_STATES;
        },
        entryLogOptions() {
            return ['Show actual time of entry', 'Hide actual time of entry'];
        },
        temperaturePredictionsOptions() {
            return ['On', 'Off'];
        },
        entryLog: {
            get() {
                return this.data.entryCreatedAtHidden ? this.entryLogOptions[1] : this.entryLogOptions[0];
            },
            async set(value) {
                if (value === this.entryLogOptions[1]) {
                    this.data.entryCreatedAtHidden = true;
                } else if (value === this.entryLogOptions[0]) {
                    this.data.entryCreatedAtHidden = false;
                } else {
                    console.error(`Can't match entry log option with value`);
                }
            },
        },
        tempPrefill: {
            get() {
                return this.data.tempPredictionsEnabled
                    ? this.temperaturePredictionsOptions[0]
                    : this.temperaturePredictionsOptions[1];
            },
            set(value) {
                this.data.tempPredictionsEnabled = value === this.temperaturePredictionsOptions[0];
            },
        },
        regCodeRequired() {
            return this.data?.country?.id === EST_COUNTRY_ID ? 'required' : '';
        },
        vatPlaceholder() {
            if (this.country && this.country.code === 'EE') {
                return 'EExxxxxxxxx';
            } else if (this.country && this.country.code === 'DE') {
                return 'DExxxxxxxxx';
            } else {
                return '';
            }
        },
        vatCodeRegex() {
            return vatCodeRegex;
        },
        isEuCountries() {
            const countryCode = this.data?.country?.code;

            return countryCode && euCountries?.includes(countryCode);
        },
        country() {
            return this.data.country;
        },
        hasStates() {
            return !!this.countryStates?.length;
        },
    },

    watch: {
        country: async function (val) {
            if (val !== null) {
                await this.$store.dispatch('setCountryStates', val.id);
            }
        },
        value: {
            immediate: true,
            async handler(value) {
                if (value?.company) {
                    const { company } = value;
                    await this.$store.dispatch('setCountryStates', company?.country_id);
                    this.data.name = company.name;
                    this.data.address = company.address;
                    this.data.regCode = company.reg_code;
                    this.data.vatCode = company.vat_number;
                    this.data.email = company.email;
                    this.data.volumeSystem = company?.volume_system;
                    this.data.weightSystem = company?.weight_system;
                    this.data.tempScale = company?.temperature_scale;
                    this.data.entryCreatedAtHidden = company?.entry_created_at_hidden;
                    this.data.tempPredictionsEnabled = company?.is_temp_predictions_enabled;
                    this.data.currency = company?.production_currency;
                    this.data.dateFormat = company?.date_format_id;
                    if (company.preferred_language) {
                        this.data.preferredLanguage = this.availableLanguages.find(
                            (item) => item?.key === company.preferred_language
                        );
                    } else {
                        this.data.preferredLanguage = this.availableLanguages.find(
                            (item) => item?.key === this.$i18n.locale
                        );
                    }
                    if (company?.country_id) {
                        this.data.country = this.countries.find((item) => item.id === company.country_id);
                    } else {
                        const countryId = 63;
                        this.data.country = this.countries.find((item) => item.id === countryId);
                    }
                    if (company?.state_id) {
                        this.data.state = this.countryStates.find((item) => item.id === company.state_id);
                    }
                }
            },
        },
    },

    created() {
        this.$store.dispatch(FETCH_COUNTRIES);
        this.$store.dispatch(FETCH_LANGUAGES);
        this.$store.dispatch(FETCH_CURRENCIES);
    },

    methods: {
        getDateInFormat(format) {
            return new DateValue().format(format?.format);
        },
        async onSubmit(e) {
            const valid = await e.validation.validate();
            if (valid) {
                this.$emit('submit', { values: this.data, e });
                this.stopEditMode();
            } else {
                this.$toastr.e(this.$t('Mandatory fields not filled'));
            }
        },
        startEditMode() {
            this.isEditMode = true;
        },
        stopEditMode() {
            this.isEditMode = false;
        },
        async getSelectedCountryStates(countryId) {
            this.$store.dispatch('setCountryStates', countryId);
        },
    },
};
</script>
