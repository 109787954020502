var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEditable
    ? _c(
        "div",
        {
          staticClass: "base-input",
          class: { "d-none-for-print": !_vm.value && _vm.value !== 0 },
        },
        [
          _c("ValidationProvider", {
            ref: "provider",
            attrs: {
              vid: _vm.name,
              name: _vm.name,
              rules: _vm.rules,
              mode: "aggressive",
              slim: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (v) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "h-100",
                          class: {
                            "has-feedback has-error":
                              !_vm.noValidate &&
                              ((_vm.validation && _vm.validation.$error) ||
                                v.errors.length),
                            "form-group w-100 mb-0 grid-template-with-label":
                              _vm.isLabelSlot,
                            "d-flex": _vm.inline && _vm.isLabelSlot,
                            "w-100": _vm.isPrependSlot,
                          },
                        },
                        [
                          _vm.isLabelSlot
                            ? _c(
                                "label",
                                {
                                  class: _vm.labelClass,
                                  attrs: { for: _vm.id },
                                },
                                [_vm._t("label")],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.inputBlockClass,
                                {
                                  "input-group align-items-center":
                                    _vm.isAppendSlot || _vm.isPrependSlot,
                                },
                              ],
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.val,
                                    expression: "val",
                                  },
                                ],
                                attrs: { type: "hidden" },
                                domProps: { value: _vm.val },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.val = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.isPrependSlot
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "input-group-prepend",
                                      class: {
                                        "align-prepend": _vm.alignPrepend,
                                      },
                                    },
                                    [_vm._t("input-prepend")],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.inputType === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.val,
                                        expression: "val",
                                      },
                                    ],
                                    ref: "inputField",
                                    staticClass: "form-control",
                                    class: [
                                      {
                                        "is-invalid":
                                          !_vm.noValidate &&
                                          ((_vm.validation &&
                                            _vm.validation.$error) ||
                                            v.errors.length),
                                        "border-danger": _vm.hasInvalid,
                                      },
                                    ],
                                    attrs: {
                                      id: _vm.id,
                                      name: _vm.name,
                                      "aria-invalid":
                                        v.ariaInput["area-invalid"],
                                      "aria-required":
                                        v.ariaInput["aria-required"],
                                      autocomplete: _vm.autocomplete,
                                      disabled: _vm.disabled,
                                      placeholder: _vm.placeholder,
                                      pattern: _vm.pattern,
                                      step: "any",
                                      lang: "en",
                                      min: _vm.minValue,
                                      max: _vm.maxValue,
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.val)
                                        ? _vm._i(_vm.val, null) > -1
                                        : _vm.val,
                                    },
                                    on: {
                                      input: _vm.onInput,
                                      focus: _vm.onFocus,
                                      blur: function ($event) {
                                        !_vm.noValidationAfterBlur &&
                                          v.validate()
                                        _vm.onBlur($event)
                                      },
                                      keypress: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.onKeypressEnter.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        _vm.onKeypress,
                                      ],
                                      change: function ($event) {
                                        var $$a = _vm.val,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.val = $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.val = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.val = $$c
                                        }
                                      },
                                    },
                                  })
                                : _vm.inputType === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.val,
                                        expression: "val",
                                      },
                                    ],
                                    ref: "inputField",
                                    staticClass: "form-control",
                                    class: [
                                      {
                                        "is-invalid":
                                          !_vm.noValidate &&
                                          ((_vm.validation &&
                                            _vm.validation.$error) ||
                                            v.errors.length),
                                        "border-danger": _vm.hasInvalid,
                                      },
                                    ],
                                    attrs: {
                                      id: _vm.id,
                                      name: _vm.name,
                                      "aria-invalid":
                                        v.ariaInput["area-invalid"],
                                      "aria-required":
                                        v.ariaInput["aria-required"],
                                      autocomplete: _vm.autocomplete,
                                      disabled: _vm.disabled,
                                      placeholder: _vm.placeholder,
                                      pattern: _vm.pattern,
                                      step: "any",
                                      lang: "en",
                                      min: _vm.minValue,
                                      max: _vm.maxValue,
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(_vm.val, null),
                                    },
                                    on: {
                                      input: _vm.onInput,
                                      focus: _vm.onFocus,
                                      blur: function ($event) {
                                        !_vm.noValidationAfterBlur &&
                                          v.validate()
                                        _vm.onBlur($event)
                                      },
                                      keypress: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.onKeypressEnter.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        _vm.onKeypress,
                                      ],
                                      change: function ($event) {
                                        _vm.val = null
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.val,
                                        expression: "val",
                                      },
                                    ],
                                    ref: "inputField",
                                    staticClass: "form-control",
                                    class: [
                                      {
                                        "is-invalid":
                                          !_vm.noValidate &&
                                          ((_vm.validation &&
                                            _vm.validation.$error) ||
                                            v.errors.length),
                                        "border-danger": _vm.hasInvalid,
                                      },
                                    ],
                                    attrs: {
                                      id: _vm.id,
                                      name: _vm.name,
                                      "aria-invalid":
                                        v.ariaInput["area-invalid"],
                                      "aria-required":
                                        v.ariaInput["aria-required"],
                                      autocomplete: _vm.autocomplete,
                                      disabled: _vm.disabled,
                                      placeholder: _vm.placeholder,
                                      pattern: _vm.pattern,
                                      step: "any",
                                      lang: "en",
                                      min: _vm.minValue,
                                      max: _vm.maxValue,
                                      type: _vm.inputType,
                                    },
                                    domProps: { value: _vm.val },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.val = $event.target.value
                                        },
                                        _vm.onInput,
                                      ],
                                      focus: _vm.onFocus,
                                      blur: function ($event) {
                                        !_vm.noValidationAfterBlur &&
                                          v.validate()
                                        _vm.onBlur($event)
                                      },
                                      keypress: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.onKeypressEnter.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        _vm.onKeypress,
                                      ],
                                    },
                                  }),
                              _vm._v(" "),
                              _vm.enableClear
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-end cursor-text",
                                      class: {
                                        "h-0": _vm.enableClear && _vm.val,
                                      },
                                      attrs: { for: _vm.id },
                                    },
                                    [
                                      _vm.val.length
                                        ? _c("i", {
                                            staticClass:
                                              "pl-2 fd-close close-icon-with-text-label",
                                            on: { click: _vm.clear },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isAppendSlot
                                ? _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [_vm._t("input-append")],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showError
                                ? [
                                    _vm.isErrorSlot && v.errors.length
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback d-block",
                                          },
                                          [
                                            _vm._t("error", null, {
                                              validation: _vm.validation,
                                            }),
                                          ],
                                          2
                                        )
                                      : !_vm.noValidate && v.errors.length
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "invalid-feedback d-block",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(v.errors[0]) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    : _c("span", [_vm._v("\n    " + _vm._s(_vm.value || "-") + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }