var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.places.length
    ? _c(
        "header",
        { staticClass: "w-100 d-none-for-print" },
        [
          _c(
            "div",
            {
              staticClass:
                "navbar-light d-flex justify-content-between flex-wrap flex-md-nowrap",
              class: {
                "fixed-top-navbar": !_vm.isSharedAccess,
                "fixed-top border-bottom": _vm.isSharedAccess,
                "fixed-top-navbar-wide": !_vm.sideNavOpen,
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "order-1 order-md-0 d-flex align-items-center space-related-out w-100 w-md-auto",
                },
                [
                  _c("SelectEstablishment", {
                    staticClass: "d-none d-lg-block",
                    attrs: {
                      "selected-company": _vm.selectedCompany,
                      "selected-place": _vm.selectedPlace,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "d-flex align-items-center w-50",
                      attrs: { href: "/" },
                    },
                    [
                      _c("Logo", {
                        staticClass: "d-lg-none",
                        attrs: {
                          responsive: "",
                          "responsive-height": 35,
                          "responsive-width": 35,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("SelectEstablishment", {
                    staticClass: "d-lg-none",
                    attrs: {
                      "selected-company": _vm.selectedCompany,
                      "selected-place": _vm.selectedPlace,
                    },
                  }),
                  _vm._v(" "),
                  !_vm.isSharedAccess
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-link d-lg-none",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.mobileHeader = true
                            },
                          },
                        },
                        [_c("span", { staticClass: "navbar-toggler-icon" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showCampaignBanner
                ? _c("HeaderBanner", {
                    attrs: {
                      id: "campaign-banner",
                      message: _vm.campaignBannerText,
                      "button-text": _vm.$t("Book a demo"),
                    },
                    on: { click: _vm.openBookADemo },
                  })
                : _vm.showDaysLeftBanner
                ? _c("HeaderBanner", {
                    attrs: {
                      id: "days-left-banner",
                      message: _vm.daysLeftBannerText,
                      "button-text": _vm.$t("Subscribe"),
                    },
                    on: { click: () => _vm.$router.push({ name: "billing" }) },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isSharedAccess
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-none order-2 d-lg-flex align-items-center",
                      attrs: { id: "topNav" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "my-2 my-lg-0 dropdown-menu-left" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("HelpButton", { staticClass: "mr-1" }),
                              _vm._v(" "),
                              _c("NotificationsButton", {
                                staticClass: "mr-3",
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "mr-3" }, [
                                _c("div", { staticClass: "btn-group" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "d-flex align-items-center cursor-pointer user-logo text-decoration-none",
                                      attrs: {
                                        id: "profile",
                                        "aria-expanded": "false",
                                        "aria-haspopup": "true",
                                        "data-toggle": "dropdown",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fd-user-circle font-size-lg space-related-out-r text-dark",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-dark mr-1",
                                          attrs: { id: "profile-name" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.getUserDisplayName) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "fd-chevron-down font-size-sm text-dark",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-right",
                                      attrs: { id: "nav-menu" },
                                    },
                                    [
                                      _vm._l(
                                        _vm.userSettings,
                                        function (setting, setupIndex) {
                                          return _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  value: _vm.$t(
                                                    setting.tooltip
                                                  ),
                                                  expression:
                                                    "$t(setting.tooltip)",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              key: `${setupIndex}_${setting.title}`,
                                            },
                                            [
                                              _c(
                                                "RouterLink",
                                                {
                                                  staticClass:
                                                    "dropdown-item py-3 d-none d-lg-flex align-items-center",
                                                  class: setting.class,
                                                  attrs: {
                                                    to: setting.to || "",
                                                    tag: "button",
                                                    disabled: !setting.to,
                                                    event: setting.event,
                                                  },
                                                },
                                                [
                                                  setting.icon
                                                    ? _c("i", {
                                                        class: `${setting.icon} mr-2`,
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(setting.title)
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              setting.separator
                                                ? _c("BDropdownDivider")
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "dropdown-item py-3 d-flex align-items-center",
                                          attrs: { type: "button" },
                                          on: { click: _vm.logout },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fd-sign-out mr-2",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "d-inline-block" },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.$t("log_out")) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              class: { visible: _vm.mobileHeader },
              attrs: { id: "mobileTopNav" },
            },
            [
              _c(
                "div",
                { staticClass: "my-2 my-lg-0 ml-auto dropdown-menu-left" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "navbar-nav w-100 mobile-font",
                          attrs: { id: "mobile-nav-menu" },
                          on: {
                            click: function ($event) {
                              _vm.mobileHeader = false
                            },
                          },
                        },
                        [
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item",
                              attrs: { tag: "li", to: { name: "overview" } },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link text-white ml-4",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("overview")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item",
                              attrs: { tag: "li", to: { name: "plan" } },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link text-white ml-4",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("plan")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item",
                              attrs: {
                                tag: "li",
                                to: { name: "activity-log" },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link text-white ml-4",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("Activity Log")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item",
                              attrs: {
                                tag: "li",
                                to: { name: "traceability" },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link text-white ml-4",
                                  attrs: { href: "#" },
                                },
                                [_vm._v(_vm._s(_vm.$t("food-production")))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item",
                              attrs: { tag: "li", to: { name: "audits.list" } },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link text-white ml-4",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("Audit")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item separator",
                              attrs: { to: { name: "team" }, tag: "li" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link text-white ml-4",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("Team")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item separator",
                              attrs: { to: { name: "documents" }, tag: "li" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link text-white ml-4",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("documents")) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "RouterLink",
                            {
                              staticClass: "nav-item",
                              attrs: { tag: "li", to: { name: "set-up" } },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "nav-link text-white ml-4 d-inline-flex align-items-center",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("Set up")) +
                                      "\n                                "
                                  ),
                                  _c("BadgeNew", { staticClass: "ml-2" }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "bot" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "dropdown-item mobile-font pt-2 pb-3 text-white",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.mobileHeader = false
                            _vm.showMobileSetup = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getUserDisplayName) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-primary mobile-font log-out",
                        attrs: { id: "mobile-log-out-btn", type: "button" },
                        on: { click: _vm.logout },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("log_out")) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close-button btn btn-link",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.mobileHeader = false
                    },
                  },
                },
                [_c("i", { staticClass: "fd-close text-primary close-icon" })]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showMobileSetup
            ? _c("Sidemenu", {
                attrs: {
                  items: _vm.userSettings.map((o) => ({
                    ...o,
                    title: _vm.$t(o.title),
                  })),
                  "hide-on-back": "",
                  "hide-toggle": "",
                  title: _vm.getUserDisplayName,
                  order: 3,
                },
                model: {
                  value: _vm.showMobileSetup,
                  callback: function ($$v) {
                    _vm.showMobileSetup = $$v
                  },
                  expression: "showMobileSetup",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("ReviewChangeProposalsModal", {
            ref: "reviewChangeProposalsModal",
          }),
        ],
        1
      )
    : _vm.newAccount || !_vm.places.length
    ? _c("NewAccountHeader")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }