var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      size: _vm.step === _vm.endStep ? "md" : "lg",
      "modal-class": "baseModal review-changes-modal",
      "footer-class": "modalFooter",
      "hide-header": "",
      centered: "",
      "no-close": "",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _vm.step === 1
                ? _c("div", [
                    _c("div", { staticClass: "row space-related-out-b" }, [
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("img", {
                          attrs: {
                            src: "/assets/img/no_specialist.png",
                            alt: "No specialist",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-6 d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "my-auto text-center text-md-left" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "fs-2 font-weight-500 space-related-out-b",
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "Please review updates in standard content"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "We have made improvements to our system that affect your created HACCP plan. Please review the planned changes."
                                  )
                                ),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row space-related-out-t" }, [
                      _c(
                        "div",
                        { staticClass: "col text-center text-md-right" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-outline-primary space-related-out-r",
                              on: { click: _vm.hide },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("Skip for today")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: { click: _vm.nextStep },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("next")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm.step === _vm.endStep
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "space-related-out-b font-size-lg font-weight-500",
                      },
                      [_vm._v(_vm._s(_vm.$t("All changes reviewed")))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "space-related-out-y" }, [
                      _vm._v(_vm._s(_vm.$t("Thank you for your attention!"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: { click: _vm.hide },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("close")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ])
                : _c(
                    "div",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "space-related-out-b font-size-lg font-weight-500",
                        },
                        [_vm._v(_vm._s(_vm.$t(_vm.current.title)))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-6 right d-flex align-items-center justify-content-center",
                          },
                          [
                            _c("BaseRadio", {
                              attrs: {
                                id: "2",
                                name: "chose-version",
                                val: _vm.REJECTED,
                                label: _vm.$t("Keep current version"),
                                inline: "",
                              },
                              model: {
                                value: _vm.chooseDecisionType,
                                callback: function ($$v) {
                                  _vm.chooseDecisionType = $$v
                                },
                                expression: "chooseDecisionType",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-6 left d-flex" },
                          [
                            _c("BaseRadio", {
                              attrs: {
                                id: "1",
                                label: _vm.$t("Accept changes"),
                                inline: "",
                                name: "chose-version",
                                val: _vm.APPROVED,
                              },
                              model: {
                                value: _vm.chooseDecisionType,
                                callback: function ($$v) {
                                  _vm.chooseDecisionType = $$v
                                },
                                expression: "chooseDecisionType",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.currentKeys, function (proposal, index) {
                        return _c(
                          "div",
                          {
                            key: proposal,
                            staticClass: "row",
                            class: { "font-weight-500": proposal === "hazard" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-6 right space-related-in-t" },
                              [
                                _vm.isNew
                                  ? [
                                      index === 0
                                        ? _c(
                                            "div",
                                            { staticClass: "font-weight-400" },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "This section is newly added"
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._l(
                                      _vm.current[proposal].current,
                                      function (section, sectionIndex) {
                                        return _c(
                                          "span",
                                          { key: sectionIndex },
                                          [
                                            sectionIndex === 0 &&
                                            proposal !== "hazard"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t(proposal))
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(section),
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 left space-related-in-t" },
                              _vm._l(
                                _vm.current[proposal].new,
                                function (section, sectionIndex) {
                                  return _c("span", { key: sectionIndex }, [
                                    sectionIndex === 0 && proposal !== "hazard"
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.$t(proposal))),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: { innerHTML: _vm._s(section) },
                                    }),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "space-related-out-t d-flex justify-content-between",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-outline-primary space-related-out-r",
                              on: { click: _vm.previousStep },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("Previous")) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: { click: _vm.nextStep },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("next")) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
              _vm._v(" "),
              _vm._t("default"),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }