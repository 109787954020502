var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBanner
    ? _c("div", { staticClass: "float-bottom-mobile" }, [
        _c(
          "div",
          {
            staticClass:
              "download-notification shadow w-100 space-related-in font-size-xs",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "mb-2 d-flex align-items-center justify-content-between",
              },
              [
                _c("button", { staticClass: "btn btn-icon" }),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("FoodDocs is even better in the mobile app")
                      ) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn btn-icon", on: { click: _vm.dismiss } },
                  [_c("i", { staticClass: "fd-close icon-dark" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "mb-2 w-100 d-flex justify-content-center align-items-center",
              },
              [
                _c("img", {
                  attrs: { src: _vm.image, alt: _vm.image, height: "60" },
                  on: { click: _vm.openStore },
                }),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }