var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "skeleton-table" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.rows, function (row) {
        return _c(
          "div",
          { key: row, staticClass: "skeleton-row" },
          _vm._l(_vm.columns, function (col) {
            return _c("div", { key: col, staticClass: "skeleton-cell" })
          }),
          0
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "skeleton-row w-100" }, [
      _c("div", { staticClass: "skeleton-cell w-100" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }