var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BCard",
    [
      _c(
        "BCardHeader",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.id,
              expression: "id",
            },
          ],
          attrs: { "header-tag": "header", role: "tab" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between bg-transparent",
            },
            [
              _vm._t("header"),
              _vm._v(" "),
              _c("i", { staticClass: "fd-chevron-down" }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "BCollapse",
        {
          staticClass: "card-content",
          attrs: {
            id: _vm.id,
            accordion: `accordion-${_vm.id}`,
            role: "tabpanel",
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [_c("BCardBody", [_vm._t("body")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }