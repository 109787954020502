var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "order-0 order-lg-1 d-flex justify-content-center bg-warning font-size-xs px-2 border-radius align-items-center space-related-out py-2 px-3",
    },
    [
      _c("span", { staticClass: "d-flex align-items-center" }, [
        _c("i", { staticClass: "fd-info mr-2" }),
        _vm._v(" "),
        _c("span", { staticClass: "text-gray-700" }, [
          _vm._v("\n            " + _vm._s(_vm.message) + "\n        "),
        ]),
      ]),
      _vm._v(" "),
      _vm.buttonText
        ? _c(
            "button",
            {
              staticClass:
                "space-unrelated-out-l ml-2 btn btn-primary btn-black font-size-xs",
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n    ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }