var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("InfoBox", { attrs: { id: "setup.place.intro_text" } }, [
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.$t("setup.place.intro_text")) },
        }),
      ]),
      _vm._v(" "),
      _c("PlaceForm", {
        attrs: { value: _vm.selectedPlace },
        on: { submit: _vm.update },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }